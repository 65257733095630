@mixin horizontal{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}
@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}
@mixin vertical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}
