
.cp_rules_title{
    p{
        padding-bottom: 3px !important;
    }
}
.new-cp {
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    padding:40px;
    background-color:#ffffff;
    color:#000;
    font-size:14px;
    z-index: 9999;
    border-top:2px solid #aaaaaa;
    .more_btn{
        text-transform: uppercase;
        padding-left: 28.8px !important;
        padding-right: 28.8px !important;
        border: 0 !important;
        &.btn1{
            background-color: #023671;
            border-color: #023671;
        }
        &.btn2{
            border-color: transparent;
            background-color: transparent;
            color: #26a39a;
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
        &.btn3{
            background-color: #26a39a;
            border-color: #26a39a;
            &:hover{
                color: #023671;
            }
        }
        &:after{
            background-image: none !important;
            content: '' !important;
        }
    }
    svg {
        width:100px;
        height:100px;
        path {
            fill:#aaaaaa;
        }
    }

    h3, p {
        //padding-bottom:15px;
    }


    > div {
        display:inline-block;
        margin-right: -4px;
        vertical-align: top;
    }

    .new-cp-content {
        padding-left:30px;
        width:calc(100% - 100px);

    }

    button {
        //margin-right: 10px;
    }

    .new-cp-content-settings-options-one-cookie {
        //cursor: pointer;
        transition: 0.2s ease;
        display: flex;
        margin-bottom: 16px;
        &.no_click_rule{
            strong{
                @include horizontal;
                &:after{
                    content: '(wymagane)';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 150% */
                    color: #5C6069;
                }
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
        &:hover {
            //opacity:0.6;
        }

        > div {
            display:inline-block;
            margin-right: -4px;
            vertical-align: top;

            &.new-cp-content-settings-options-one-cookie-checkbox {
                width:20px;
                margin-right: 10px;

                > div {
                    border:1px solid #92959b;
                    width:20px;
                    height:20px;
                    position:relative;
                    background-color:#ffffff;;
                }
            }

            &.new-cp-content-settings-options-one-cookie-desc {
                width:calc(100% - 30px);
                padding-top:2px;
            }
        }

        &.on {
            .new-cp-content-settings-options-one-cookie-checkbox {
                > div {
                    background-color:#023671;;
                    border-color: #023671;;
                    &:after {
                        content:"";
                        @include fill;
                        background-color:#023671;
                        @include bg_pos;
                        background-image: url("./../img/check.svg");
                        background-size: 10px auto;
                    }
                }
            }
        }

        &.disabled {
            .new-cp-content-settings-options-one-cookie-checkbox {
                > div {
                    //opacity:0.2;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .cp_btns_holder{
        display: block;
    }
}
.cp_btns{
    .row{
        @include horizontal;
        gap: 15px;
        //gap: 20px;
        @media only screen and (max-width: 767px) {
            display: block;
        }
        > div{
            width: auto;
            padding-left: 0;
            padding-right: 0;
            width: auto;
            &:first-child{
                margin-right: auto;
            }
            &:nth-child(2){
                margin-left: auto;
                //margin-right: 8px;
            }
        }
    }
}

.new-cp{
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    label{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        color: #5C6069;
    }
    .new_cp_holder{
        @include fill;
        @include vertical;
        &.settings_open{
            .new_cp_content{
                width: 770px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
    }
        @media only screen and (max-width: 767px) {
            display: block;
            overflow-y: scroll;
            padding: 50px 15px;
        }
        > div{
            width: 100%;
        }
    }
    .new_cp_content{
        position: relative;
        color: #031F83;
        width: 640px;
        //transition: width 0.5s;
        margin: 0 auto;
        border-radius: 10px;
        padding: 32px 40px;
        background-color: #ffffff;
        //@include small_font_set1;
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 60px 30px;
            .btn1, .btn3{
                width: 100%;
            }
        }
        .cp_close{
            position: absolute;
            top: 20px;
            right: 20px;
            display: none;
        }
        .default_font{
            color: #101113;
            strong{
                font-weight: 600;
            }
        }
        .desc{
            //text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            color: #5C6069;
            //p{
            //    padding-top: 21px;
            //}
        }
        a{
            color: #0A5ED7;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .cp_icon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        @include bg_pos;
        background-image: url("./../img/cookie.svg");
        background-color: #F6F7FA;
        margin: 0 auto 16px auto;
    }
    h2{

        color: #052169;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 133.333% */
        letter-spacing: -0.24px;
        text-align: center;
        margin-bottom: 16px;
        @media only screen and (max-width: 1660px) {
            font-size: 22px;
            line-height: 27px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 19px;
            line-height: 34px;
            letter-spacing: -0.02em;
        }
    }
    .cp_btns{
        padding-top: 26px;
        @media only screen and (max-width: 767px) {
            > div{
                > div{
                    margin-bottom: 17px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.new-cp-content-settings-options-one-cookie {
    transition: 0.2s ease;

    > div {
        display:inline-block;
        margin-right: -4px;
        vertical-align: top;

        &.new-cp-content-settings-options-one-cookie-checkbox {
            width:20px;
            margin-right: 10px;

            > div {
                border-radius: 5px;
                border: 2px solid #d6d7dd;
                width:20px;
                height:20px;
                position:relative;
            }
        }

        &.new-cp-content-settings-options-one-cookie-desc {
            width:calc(100% - 30px);
            padding-top:2px;
            p{
                margin-bottom: 8px;
                strong{
                    color: #101113;
                    display: block;
                    margin-bottom: 4px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }

    &.on {
        .new-cp-content-settings-options-one-cookie-checkbox {
            > div {
                border-color: #0A5ED7;
                &:after {
                    content:"";
                    @include fill;
                    @include bg_pos;
                    background-color: #0A5ED7;;
                    background-image: url("./../img/check_icon_check.svg");
                }
            }
        }
    }
}

.cp_rules{
    padding-top: 32px;
    > div{
        margin-bottom: 6px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.no_click_checkbox{
    //opacity: 0.2;
}

.no_click_checkbox, .no_click_rule{
    cursor: default;
}

label{
    color: #0A5ED7;;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    color: #616e7b;
}

@media only screen and (max-width: 767px) {
    .new-cp{
        .btn2{
            width: 100%;
        }
    }
}

.main_desc, .additional_desc{
    text-align: center;
}

.additional_desc{
    //padding-top: 20px;
}

.no_click_rule{
    .no_click_rule{
        &.disabled{
            opacity: 0.5;
        }
    }

}
