@import "plugins/bootstrap/bootstrap";
@import "variables";
@import "cookie_policy";
//@import "../js/plugins/lightbox/lightbox.scss";

$border_color:#dfe4ef;
$accent_color1:#023671;
$accent_color2:#26a39a;
$accent_color3:#5bcbe7;
$accent_color4:#ec6095;
$top_color:#023671;

$special_font:'Muli', sans-serif;
$main_font:'Open Sans', sans-serif;
$font_color:#5e6b80;
$font_color2:#10324f;
$header_color: $accent_color1;

$black:#000000;

$white:#ffffff;

$theme_color:#f5f7f9;
$theme_color2:#edf0f3;

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

@mixin bg_def_pos {
    background-repeat: no-repeat;
    background-position: center;
}

@mixin vertical {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@mixin shadow {
    -webkit-box-shadow: 0px 0px 27px 5px rgba(221,226,232,1);
    -moz-box-shadow: 0px 0px 27px 5px rgba(221,226,232,1);
    box-shadow: 0px 0px 27px 5px rgba(221,226,232,1);
}

@mixin gradient1 {
    background: rgba(21,26,35,0);
background: -moz-linear-gradient(top, rgba(21,26,35,0) 35%, rgba(0,0,0,0.55) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(35%, rgba(21,26,35,0)), color-stop(100%, rgba(0,0,0,0.55)));
background: -webkit-linear-gradient(top, rgba(21,26,35,0) 35%, rgba(0,0,0,0.55) 100%);
background: -o-linear-gradient(top, rgba(21,26,35,0) 35%, rgba(0,0,0,0.55) 100%);
background: -ms-linear-gradient(top, rgba(21,26,35,0) 35%, rgba(0,0,0,0.55) 100%);
background: linear-gradient(to bottom, rgba(21,26,35,0) 35%, rgba(0,0,0,0.55) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151a23', endColorstr='#000000', GradientType=0 );
}

body{
    font-family: $main_font;
    ul{
        list-style: none;
        padding: 0;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
        font-weight: 400;
    }
    img{
        max-width: 100%;
        height: auto;
    }
    a{
        &:hover{
            text-decoration: none;
        }
    }
    h2{
        font-size: 22.5px;
        line-height: 31.5px;
        color: $header_color;
        @media only screen and (max-width: 1500px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
    font-size: 12.6px;
    line-height: 16.2px;
    color: $font_color;
}

.page{
    display: none;
}

.btn12345{
    padding: 10px 10px;
    border: 1px solid $accent_color1;
    display: inline-block;
}

.more_btn{
    color: $white;
    height: 45px;
    line-height: 45px;
    position: relative;
    font-size:9px;
    font-weight: 800;
    font-family: $special_font;
    display: inline-block;
    padding-right: 29.7px;
    @include anim;
    &:hover{
        color: $accent_color2;
        text-decoration: none;
        &:after{
            right: -9px;
        }
    }
    &:after{
        position: absolute;
        content: '';
        width: 18px;
        height: 10.8px;
        top: 17.1px;
        right: 0;
        @include bg_def_pos;
        background-image: url('./../img/more_arr1.png');
        @include anim;
    }
}

.more_btn2{
    padding-left: 28.8px;
    padding-right: 36px;
    height: 45px;
    background-color: $accent_color1;
    display: inline-block;
    cursor: pointer;
    &.back{
        span{
            padding-right: 0;
            padding-left: 36px;
            &:after{
                right: auto;
                left: 0;
                -moz-transform: scale(-1, 1);
                -webkit-transform: scale(-1, 1);
                -o-transform: scale(-1, 1);
                -ms-transform: scale(-1, 1);
                transform: scale(-1, 1);
            }
        }
    }
    &:hover{
        span{
            color: $accent_color2;
            &:after{
                right: -10px;
            }
        }
    }
    span{
        display: block;
        font-size: 9.9px;
        line-height: 45px;
        color: $white;
        text-transform: uppercase;
        font-weight: 800;
        font-family: $special_font;
        position: relative;
        padding-right: 36px;
        @include anim;
        &:after{
            position: absolute;
            content: '';
            width: 20px;
            height: 9.8px;
            top: 19px;
            right: 0;
            @include bg_def_pos;
            background-image: url('./../img/more_arr1.png');
            @include anim;
            background-size:auto 100%;
        }
    }

}

.more_btn3{
    text-align: center;
    display: inline-block;
    font-size: 9.9px;
    line-height: 36px;
    color: $white;
    text-transform: uppercase;
    font-weight: 800;
    font-family: $special_font;
    position: relative;
    padding: 0 27px;
    @include anim;
    height: 36px;
    background: rgba(2,54,113, 0.8);
    border-radius: 5px;
    &:after{
        margin-left: 7px;
        content: '>';
        color: #46c2bd;
    }
}

.more_btn4{
    padding-left: 28.8px;
    padding-right: 25px;
    height: 35px;
    background-color: $accent_color1;
    display: inline-block;
    &:hover{
        span{
            color: $accent_color2;
            &:after{
                right: -10px;
            }
        }
    }
    span{
        display: block;
        font-size: 10.5px;
        line-height: 35px;
        color: $white;
        //text-transform: uppercase;
        font-weight: 800;
        font-family: $special_font;
        position: relative;
        padding-right: 36px;
        @include anim;
        &:after{
            position: absolute;
            content: '';
            width: 20px;
            height: 9.8px;
            top: 12px;
            right: 0;
            @include bg_def_pos;
            background-image: url('./../img/more_arr1.png');
            @include anim;
            background-size:auto 100%;
        }
    }

}

.default_font{
    font-size: 11.35px;
    line-height: 18.5px;
    color: $font_color;
    hr{
        border-bottom: 1px solid #dfe4ef;
    }
    &.large{
        font-size:12.5px;
        line-height: 27px;
        @media only screen and (max-width: 1500px) {
            font-size: 11px;
            line-height: 26px;
        }
    }
    h1{
        font-size: 27px;
        line-height: 39.6px;
        @media only screen and (max-width: 1500px) {
            font-size: 26px;
            line-height: 41px;
        }
    }
    h2{
        font-size: 24.3px;
        line-height: 34.3px;
        @media only screen and (max-width: 1500px) {
            font-size: 24px;
            line-height: 34px;
        }
    }
    h3{
        font-size: 24px;
        line-height: 34px;
        @media only screen and (max-width: 1500px) {
            font-size: 22px;
            line-height: 32px;
        }
    }
    h4{
        font-size: 21px;
        line-height: 31px;
        @media only screen and (max-width: 1500px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
    h5{
        font-size: 17px;
        line-height: 27px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 26px;
        }
    }
    h6{
        font-size: 14px;
        line-height: 24px;
    }
    ul{
        padding-left: 18px;
        list-style: disc;
    }
    ol{
        padding-left: 18px;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    h1{
        strong{
            //font-weight: 800;
            font-weight: 400;
        }
    }

    h2,h3,h4,h5,h6{
        strong{
            font-weight: 800;
        }
    }

    img{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    a{
        color:rgb(7, 130, 193);
    }
    //table{
    //    thead{
    //        tr{
    //            td{
    //                background: rgba(51,122,183,0.8);
    //                color: $white;
    //                h1,h2,h3,h4,h5,h6{
    //                    color: $white !important;
    //                }
    //            }
    //        }
    //    }
    //    tbody{
    //        tr{
    //            td{
    //                background: #f8f8f8;
    //                font-size: 13px;
    //            }
    //        }
    //    }
    //    td{
    //        padding: 15px 0;
    //        color: $font_color;
    //        a{
    //            color: $font_color;
    //        }
    //    }
    //}
}

.header{
    padding-top: 40px;
    position: relative;
    z-index: 10;

    .inline_row{
        > div{
            display: inline-block;
            vertical-align: bottom !important;
            float: none;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            &:first-child{
                padding-bottom: 50px;
                @media only screen and (max-width: 1500px) {
                    padding-bottom: 30px;
                    @media only screen and (max-width: 991px) {
                        padding-bottom: 0;
                    }
                }

            }
        }
    }

    @media only screen and (max-width: 1500px) {
        padding-top: 20px;
    }
    @media only screen and (max-width: 991px) {
        .col-md-11{
            display: flex;
            flex-flow: column;
            > div{
                align-items: stretch;
                &.header_top{
                    order: 2;
                }
                &.header_bottom{
                    order: 1;
                    display: none;
                }
            }
        }
    }
    div{
        &.logo_box{
            position: relative;

            @media only screen and (max-width: 991px) {
                img{
                    height: 50px;
                    width: auto;
                }
                div.rwd_btn{
                    width: 30px;
                    position: absolute;
                    right: 0;
                    top: 17px;
                    > div{
                        width: 100%;
                        height: 3px;
                        margin-bottom: 3px;
                        background: $font_color2;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &.header_top{
            position: relative;
            text-align: right;
            z-index: 3;
            @media only screen and (max-width: 991px) {
                margin: 0 0 15px 0;
            }
            .header_more{
                display: none;
                position: absolute;
                left: 0;
                top: 100%;
                background: #ededed;
                width: 100%;
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &:first-child{
                        width: calc(100% - 50px);
                    }
                    &.close_it{
                        @include anim;
                        cursor: pointer;
                        width: 50px;
                        height: 50px;
                        @include bg_def_pos;
                        background-image: url('./../img/close_it_icon.png');
                        &:hover{
                            background-color: rgba(255,255,255,0.8);
                        }
                    }
                }
                &.login_box1{
                    height: 50px;
                    line-height: 50px;
                }
                @media only screen and (max-width: 991px) {
                    width:100%;
                }
                .holder{
                    line-height: 0;
                    form{
                        width: 100%;
                    }
                }
                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    font-family: $special_font;
                        color: $font_color2;

                  }
                  ::-moz-placeholder { /* Firefox 19+ */
                    font-family: $special_font;
                    color: $font_color2;
                  }
                  :-ms-input-placeholder { /* IE 10+ */
                    font-family: $special_font;
                    color: $font_color2;
                  }
                  :-moz-placeholder { /* Firefox 18- */
                    font-family: $special_font;
                    color: $font_color2;
                  }
                input{
                    outline: none;
                    vertical-align: top;
                    border: none;
                    margin: 0;
                    background-color: transparent;
                    height:50px;
                    &.s_input{
                        font-weight: 600;
                        font-size: 11.5px;
                        font-family: $special_font;
                        color: $font_color2;
                        width: calc(100% - 50px);
                        padding-right: 30px;
                        padding-left: 20px;
                        /*@media only screen and (max-width: 1200px) {
                            width: 210px;
                        }*/

                    }
                    &.s_submit{
                        font-size: 0;
                        @include bg_def_pos;
                        background-image: url('./../img/search.png');
                        width: 50px;
                        @include anim;
                        &:hover{
                            background-color: rgba(255,255,255,0.8);
                        }
                    }
                }
                .login_register{
                    padding: 0 16px;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding-bottom: 15px;
                        padding-right: 16px;
                    }
                    ul{
                        li{
                            margin-right: 25px;
                            &:last-child{
                                margin-right: 0;
                            }
                            @media only screen and (max-width: 991px) {
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                            div{
                                a{
                                    text-transform: uppercase;
                                    font-size: 10.5px;
                                    line-height: 10.5px;
                                    color: $font_color2;
                                    font-weight: 800;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
            > ul{
                > li{
                    div.holder{
                        padding: 0px 16px 0 20px;
                        @media only screen and (max-width: 991px) {
                            padding:0 0 0 0;
                            line-height: 0;
                        }
                    }
                }
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    &.top_left_side{
                        background: #f8f8f8;
                        position: relative;
                        padding: 12.5px 0;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding: 12.5px;
                        }
                       .holder{
                        > ul{
                            >  li{
                                margin-right: 20px;
                                &:last-child{
                                    margin-right: 0;
                                }
                                  &.bip{
                                      a{
                                          font-size: 9px;
                                          line-height: 21px;
                                          color: $font_color2;
                                          text-decoration: none;
                                          padding-left: 26px;
                                          height: 21px;
                                          background-position: left top;
                                          background-repeat: no-repeat;
                                          background-image: url('./../img/bip.png');
                                          font-family: $special_font;
                                          font-weight: 600;
                                          display: inline-block;
                                          @media only screen and (max-width: 991px) {
                                            padding-left: 16px;
                                            width: 16px;
                                            overflow: hidden;
                                          }
                                      }
                                  }
                                  &.social{
                                    @media only screen and (max-width: 991px) {
                                        margin-right: 10px;
                                    }
                                      img{
                                          height: 15px;
                                          width: auto;
                                          margin: 0 5px;
                                      }
                                  }
                                  &.fonts{
                                      a{
                                          font-weight: 600;
                                          padding: 0 3px;
                                          color: $font_color2;
                                      }
                                    @media only screen and (max-width: 991px) {
                                        display: none;
                                    }
                                      ul{
                                          li{
                                              line-height: 13px;
                                              margin-right: 2px;
                                              cursor: pointer;
                                              &:last-child{
                                                  margin-right: 0;
                                              }
                                              &.font_smaller{
                                                  font-size: 11px;
                                              }
                                              &.font_larger{
                                                  font-size: 13px;
                                              }
                                          }
                                      }
                                  }
                                  &.contrast{
                                    @media only screen and (max-width: 991px) {
                                        display: none;
                                    }
                                      img{
                                          cursor: pointer;
                                      }
                                  }
                                  &.show_top_more{
                                      cursor: pointer;
                                  }
                              }
                          }
                       }
                    }
                }
            }
        }
        &.search{
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
        &.header_bottom{
            text-align: right;
            @media only screen and (min-width: 991px) {
                display: block !important;
            }
            @media only screen and (max-width: 991px) {
                display: none;
            }
            div.menu_box{
                @media only screen and (max-width: 991px) {
                    nav.menu{
                        > ul{
                            > li{
                                width: 100%;
                                margin-right: 0;
                                text-align: left;
                                ul{
                                    width: 100% !important;
                                    display: none;
                                    position: relative !important;
                                    visibility: visible !important;
                                    opacity: 1 !important;
                                    box-shadow: none !important;
                                    left: 0 !important;
                                }
                            }
                        }
                    }
                }

                &.desctop{
                    nav.menu{
                        &.small_margin{
                            > ul{
                                > li{
                                    //margin-right: 80px;
                                    margin-right: 60px;
                                    @media only screen and (max-width: 1500px) {
                                        margin-right: 60px;
                                    }
                                     @media only screen and (max-width: 1500px) {
                                         margin-right: 40px;
                                     }
                                     @media only screen and (max-width: 991px) {
                                         margin-right: 0;
                                         width: 100%;
                                         text-align: left;
                                     }
                                }
                            }
                        }
                       > ul{
                           > li{
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 120px;
                                position: relative;
                               @media only screen and (max-width: 1500px) {
                                   margin-right: 80px;
                               }
                                @media only screen and (max-width: 1200px) {
                                    margin-right: 60px;
                                }
                                @media only screen and (max-width: 991px) {
                                    margin-right: 0;
                                    width: 100%;
                                    text-align: left;
                                }
                                &:hover{
                                    > ul{
                                        visibility: visible;
                                        opacity: 1;
                                    }
                                    div{
                                        a{
                                            &:after{
                                                width: 100%;
                                                left: 0;
                                            }
                                        }
                                    }
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                               > div{
                                    &.on{
                                        a{
                                            &:after{
                                                width: 100%;
                                                left: 0;
                                            }
                                        }
                                    }
                                    a{
                                        padding:30px 0 50px 0;
                                        display: block;
                                        font-size: 12.5px;
                                        line-height: 12.5px;
                                        font-family: $special_font;
                                        text-decoration: none;
                                        color: $font_color2;
                                        text-transform: uppercase;
                                        font-weight: 800;
                                        position: relative;
                                        @include anim;
                                        color: $font_color2;
                                        @media only screen and (max-width: 1500px) {
                                            padding: 30px 0;
                                        }
                                        @media only screen and (max-width: 1200px) {
                                            font-size: 10.5px;
                                            line-height: 10.5px;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            padding: 15px;
                                            border-bottom: 1px solid $border_color;
                                        }
                                        &:after{
                                            @include anim;
                                            content: '';
                                            position: absolute;
                                            bottom: 0;
                                            left: 50%;
                                            width: 0;
                                            height: 2px;
                                            background: $accent_color2;
                                            @media only screen and (max-width: 991px) {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                               > ul{
                                   position: absolute;

                                   top: 100%;
                                   right: 0;
                                   text-align: left;
                                   background: $white;
                                   @include anim;
                                   visibility: hidden;
                                   opacity: 0;
                                   @include shadow;
                                   width: 200px;
                                   > li{
                                       position: relative;
                                       width: 100%;
                                       margin-right: 0;
                                       /*&:after{
                                           content: '';
                                           width: 150px;
                                           right: 100%;
                                           top: 0;
                                           position: absolute;
                                           height: 100%;
                                       }*/
                                       &:last-child{
                                           border-bottom: 0;
                                           padding-bottom: 0;
                                           margin-bottom: 0;
                                       }
                                       &:hover{
                                           > ul{
                                               opacity: 1;
                                               visibility: visible;
                                           }
                                           > div{
                                               a{
                                                   color: $accent_color2;
                                               }
                                           }
                                       }
                                       div{
                                           a{
                                               display: block;
                                               border-bottom: 1px solid $border_color;
                                               //line-height: 16px;
                                               font-size: 11.5px;
                                               line-height: 15px;
                                               @include anim;
                                               padding: 15px 15px 15px 15px;
                                               color: $font_color2;
                                               &g:after{
                                                   display: none;
                                               }
                                           }
                                       }
                                       > ul{
                                           position:absolute;
                                           opacity: 0;
                                           visibility: hidden;
                                           text-align: left;
                                           //background: $white;
                                           min-height: 100%;
                                           width: 100%;
                                           left: 100%;
                                           top: 0;
                                           @include anim;
                                           //@include shadow;
                                           > li{
                                               width: 100%;
                                               margin-right: 0;
                                               background:$white;
                                               &:last-child{
                                                   border-bottom: 0;
                                                   padding-bottom: 0;
                                                   margin-bottom: 0;
                                               }
                                               &:hover{
                                                   > div{
                                                       a{
                                                           color: $accent_color2;
                                                       }
                                                   }
                                               }
                                           }

                                       }
                                   }
                               }
                               .show_more{
                                   width: 30px;
                                   height: 30px;
                                   position: absolute;
                                   top: 0%;
                                   right: 0;
                                   background: $accent_color2;
                                   @include bg_def_pos;
                                   background-image: url('./../img/more_arr123.png');
                                   @include anim;
                                   @media only screen and (max-width: 991px) {
                                       height: 100%;
                                       top: 0;
                                   }
                                   &.o{
                                       transform: rotate(90deg);
                                   }
                               }
                            }
                        }
                    }
                }
                &.mobile{
                    nav.menu{
                        > ul{
                            > li{
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 10px;
                                position: relative;
                                &:last-child{
                                    margin-right: 0;
                                }
                                &.m_btn{
                                    .show_more{
                                        width: 30px;
                                        height: 30px;
                                        position: absolute;
                                        top: 25%;
                                        right: 0;
                                        background: $accent_color2;
                                        @include bg_def_pos;
                                        background-image: url('./../img/more_arr123.png');
                                        @include anim;
                                        @media only screen and (max-width: 991px) {
                                            height: 100%;
                                            top: 0;
                                        }
                                        &.o{
                                            transform: rotate(90deg);
                                        }
                                    }
                                }
                                > div{
                                    padding-right: 40px;
                                    position: relative;
                                    > a{
                                        padding:40px 0 40px 0;
                                        display: block;
                                        font-size: 10.5px;
                                        line-height: 10.5px;
                                        font-family: $special_font;
                                        text-decoration: none;
                                        color: $font_color2;
                                        text-transform: uppercase;
                                        font-weight: 800;
                                        position: relative;
                                        @include anim;
                                        @media only screen and (max-width: 1500px) {
                                            padding: 20px 0;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            padding: 15px;
                                            border-bottom: 1px solid $border_color;
                                        }
                                        &:after{
                                            @include anim;
                                            content: '';
                                            position: absolute;
                                            bottom: 0;
                                            left: 50%;
                                            width: 0;
                                            height: 2px;
                                            background: $accent_color2;
                                            @media only screen and (max-width: 991px) {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                > ul{
                                    position: absolute;
                                    top: 100%;
                                    right: 0;
                                    //left: 0;
                                    margin: 0 auto;
                                    text-align: left;
                                    background: $white;
                                    display: none;
                                    @include shadow;
                                    width: 200px;
                                    .show_more{
                                        top: 0!important;
                                    }
                                    > li{
                                        width: 100%;
                                        margin-right: 0;
                                        &:after{
                                            content: '';
                                            width: 150px;
                                            right: 100%;
                                            top: 0;
                                            position: absolute;
                                            height: 100%;
                                        }
                                        &:last-child{
                                            border-bottom: 0;
                                            padding-bottom: 0;
                                            margin-bottom: 0;
                                        }
                                        div{
                                            a{
                                                display: block;
                                                border-bottom: 1px solid $border_color;
                                                //line-height: 16px;
                                                font-size: 10.5px;
                                                line-height: 15px;
                                                @include anim;
                                                padding: 7px 32px 7px 7px;
                                                &:after{
                                                    display: none;
                                                }
                                            }
                                        }
                                        > ul{
                                            //position:absolute;
                                            display: none;
                                            text-align: left;
                                            background: $white;
                                            min-height: 100%;
                                            width: 100%;
                                            left: 50%;
                                            top: 0;
                                            > li{
                                                width: 100%;
                                                margin-right: 0;
                                                &:last-child{
                                                    border-bottom: 0;
                                                    padding-bottom: 0;
                                                    margin-bottom: 0;
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                nav.menu{
                    li.m{
                        background-position: right 15px center;
                        background-repeat: no-repeat;
                        background-image: url('./../img/more_arr1.png');
                        @media only screen and (max-width: 991px) {
                            background-image: none;
                        }
                    }
                    li.m_btn{
                        > div{
                            position: relative;

                            @media only screen and (max-width: 991px) {
                                overflow: hidden;
                            }

                        }
                        @media only screen and (max-width: 1200px) {
                            ul{
                                .show_more{
                                    @media only screen and (max-width: 1200px) {
                                        height: 100%;
                                        top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown{
        &.open{
            .dropdown-menu{
                top: 50%;
                opacity: 1;
                visibility: visible;
            }
        }
        .btn{
            padding: 0;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 2px solid #e8ebf0;
            background: $white;
            color: $header_color;
            font-size: 10px;
            font-weight: 700;
            position: relative;
            z-index: 1111;
        }
        .dropdown-menu{
            top: 0%;
            padding: 20px 0 5px 0;
            min-width: 0;
            text-align: center;
            border: 2px solid #e8ebf0;
            display: block;
            visibility: hidden;
            opacity: 0;
            @include anim;
            > li{
                width: 100%;
                > a{
                    padding: 0;
                    font-size: 11.5px;
                    @include anim;
                    position: relative;
                    &:hover{
                        background: $accent_color1;
                        color: $white;
                    }
                }
            }
        }
    }
}


.slider{
    background: #eee;
    width: 100%;
    position: relative;
    height: 400px;
    .slider_margin{
        padding-top: 50px;
        @media only screen and (max-width: 1500px) {
            padding-top: 40px;
        }
    }
    @media only screen and (max-width: 1500px) {
        height: 380px;
    }
    .col-md-5{
        width: 43.8%;
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
    }
    .col-md-offset-5{
        margin-left: 43.8%;
    }
    > ul{
        @include fill;
        > li{
            @include fill;
            @include bg_def_pos;
            background-size: cover;
            .slider_content{
                position: relative;
                z-index: 3;
                width: 100%;
                background: rgba(2,54,113,0.85);
                height: 300px;
                font-size: 14.5px;
                line-height: 30px;
                color: #acb4c2;
                @media only screen and (max-width: 1500px) {
                    height: 300px;
                }
                @media only screen and (max-width: 991px) {
                    height: auto;
                    text-align: center;
                    p{
                        display: none;
                    }
                }
                > div{
                    padding: 20px;
                    > div{
                        position: relative;
                        padding:20px 30px;
                        @media only screen and (max-width: 991px) {
                            padding: 0;
                        }
                        &:before{
                            content: '';
                            width:3px;
                            height: 72px;
                            background: $accent_color2;
                            position: absolute;
                            left: 0px;
                            top: 0;
                            @media only screen and (max-width: 991px) {
                                display: none;
                            }
                        }
                        &:after{
                            content: '';
                            width:70px;
                            height: 3px;
                            background: $accent_color2;
                            position: absolute;
                            left: 3px;
                            top: 0;
                            @media only screen and (max-width: 991px) {
                                display: none;
                            }
                        }
                        .desc{
                            text-align: left;
                            @media only screen and (max-width: 1200px) {
                                margin-bottom: 0;
                            }
                        }
                        .default_font{
                            color: $white;
                            margin-bottom: 15px;
                            font-family: $special_font;

                            strong{
                                font-weight: 800;
                            }
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 20px;
                                text-align: center;
                            }
                        }
                        p{
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            @media only screen and (max-width: 991px) {
                                text-align: center !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .slider_nav{
        @include fill;
        @media only screen and (max-width: 991px) {
            display: none;
        }
        .arrs{
            margin-left: -15px;
            .arr{
                width: 61px;
                line-height: 61px;
                text-align: center;
                height: 61px;
                cursor: pointer;
                &.arr_left{
                    background: rgba(53,197,187,0.8);
                }
                &.arr_right{
                    background: rgba(47,183,173,0.8);
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .text-right{
        text-align: center;
    }
}

.section_header{
    &.rss_header{
        @media only screen and (max-width: 991px) {
            text-align: center;
            .link0{
                margin-top: 15px;
                display: inline-block;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    span{
        color: #e23d3d;
    }
    margin-bottom: 36px;
    font-family: $special_font;
    .default_font{
        h1,h2,h3,h4,h5,h6{
            //color: $header_color;
            color: $top_color;
        }
    }
    &.icon_header{
        min-height: 57px;
        background-repeat: no-repeat;
        background-position: left top;
        padding-top: 10px;
        padding-left: 82px;
        margin-bottom: 30px;
        @media only screen and (max-width: 991px) {
            padding-left: 60px;
        }
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
        margin-bottom: 30px;
    }
}

.page_header{
    text-align: center;
    font-family: $special_font;
    margin-bottom: 10px;
    padding-top: 15px;
    &.date_header{
        p{
            font-size: 14px;
        }
    }
    div.default_font{
        h1,h2,h3,h4,h5,h6{
            //color: $accent_color1;
            color: $top_color;
        }
    }
    strong{
        font-weight: 800;
    }
}

.date_header{
    color: $font_color;
    font-size: 9.9px;
    line-height: 27px;
    margin-bottom: 13.5px;
    p{
        text-transform: uppercase;
        strong{
            font-weight: 600;
        }
    }
    h3{
        font-size: 18.9px;
        font-family: $special_font;
        color: $header_color;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        strong{
            font-weight: 800;
        }
    }
}

.news{
    padding-top: 30px;
    background: $theme_color;
    @media only screen and (max-width: 1500px) {
        padding-top: 20px;
    }
    .btns1{
        @media only screen and (max-width: 1200px) {
            margin-bottom: 50px;
        }
    }
    @media only screen and (max-width: 991px) {
        padding-top: 30px;
        .news_side{
            .btns1{
                margin-bottom: 0;
                text-align: center;
            }
            .more_btn4{
                margin-top: 15px;
            }
            .link0{
                display: inline-block;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 15px;
            }
        }
    }
    .fast_links{
        padding-top: 45px;
        margin-bottom: -65px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
            padding-top: 30px;
        }
        @media only screen and (max-width: 600px) {
            margin-bottom: 0;
        }
    }
    /*
    > .container{
        > .row{
            margin: 0 -30px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -15px;
            }
            > div{
                padding:0 30px;
                @media only screen and (max-width: 1500px) {
                    padding: 0 15px;
                }
            }
        }
    }*/
    div{
        &.news_items{
            margin: 0 -10px;
            @media only screen and (max-width: 991px) {
                padding-top: 30px;
            }
            .news_item {
                display: inline-block;
                vertical-align: top;
                padding: 0 10px;
                margin-bottom: 20px;
                @include anim;
                &:hover{
                    transform: scale(1.03);
                    article{
                       @include shadow;
                    }
                }
                &.small{
                   width: 25%;
                    @media only screen and (max-width: 1500px) {
                        width: 100%;
                    }
                   @media only screen and (max-width: 810px) {
                    width: 50%;
                }
                @media only screen and (max-width: 810px) {
                    width: 100%;
                }
                   article{
                        height: 153px;
                       @media only screen and (max-width: 1500px) {
                           height: auto;
                       }
                        .desc{
                            padding:10px 28px;
                            width: 100%;
                            @media only screen and (max-width: 1500px) {
                                padding: 12px 28px;
                            }
                            > .default_font{
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                @media only screen and (max-width: 1500px) {
                                    display: none;
                                }
                            }
                            .date_header{
                                margin-bottom: 15px;
                                @media only screen and (max-width: 1500px) {
                                    margin-bottom: 0;
                                    p{
                                        display: none;
                                    }
                                }
                                h3{
                                    font-size: 14px;
                                    line-height: 24px;
                                    @media only screen and (max-width: 1500px) {
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                            p{
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                }
                }

                &.medium{
                    width: 50%;
                    @media only screen and (max-width: 810px) {
                        width: 100%;
                    }
                    article{
                        height: 193.5px;
                        @media only screen and (max-width: 1500px) {
                            height: 180px;
                        }
                        @media only screen and (max-width: 810px) {
                            height: 310px;
                        }
                        .image{
                            @media only screen and (max-width: 1500px) {
                                display: none;
                            }
                        }
                        .desc{
                            padding: 20px;
                            @media only screen and (max-width: 1500px) {
                                width: 100%;
                                padding: 13px 28px;
                            }
                            .date_header{
                                margin-bottom: 15px;
                                h3{
                                    font-size: 15.3px;
                                    line-height: 24.3px;
                                    @media only screen and (max-width: 600px) {
                                        font-size: 14px;
                                        line-height: 24px;
                                     }
                                }
                            }
                            p{
                                display: -webkit-box;
                                -webkit-line-clamp: 4;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                   }
                }

                &.large {
                    width: 100%;
                   article{
                        height: 216px;
                        .desc{
                            padding: 20px 40px;
                            .default_font{
                                line-height: 25px;
                            }
                            .date_header{
                                h3{
                                    font-size: 21px;
                                    line-height: 31px;
                                    @media only screen and (max-width: 810px) {
                                        font-size: 17px;
                                        line-height: 27px;
                                    }
                                    @media only screen and (max-width: 600px) {
                                       font-size: 14px;
                                       line-height: 24px;
                                    }
                                }
                            }
                            p{
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                   }
                }
                article{
                    display: flex;
                    width: 100%;
                    @include anim;
                    @media only screen and (max-width: 810px) {
                        display: block;
                        height: auto !important;
                    }
                    div{
                        &.image{
                            @include bg_def_pos;
                            background-size: cover;
                            width: 49%;
                        }
                        &.desc{
                            width: 51%;
                            background: $white;
                            @media only screen and (max-width: 810px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        &.events{
            background: #e6eaef;
            padding: 36px 13.5px;
            margin-bottom: 20px;
            @media only screen and (max-width: 1200px) {
                padding: 30px;
            }
            .upcoming_events{
                @media only screen and (max-width: 991px) {
                    text-align: center;
                 }
                ul{
                    li{
                        margin-bottom: 30px;
                    }
                }
            }
            .calendar{
                width: 100%;
            }
            .date{
                background: $accent_color2;
                color: $white;
                font-size: 9px;
                padding: 0 9px;
                display: inline-block;
                height: 17.1px;
                line-height: 17.1px;
                white-space: nowrap;
                @media only screen and (max-width: 1500px) {
                    font-size: 9px;
                    width: 119%;
                    white-space: nowrap;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                @media only screen and (max-width: 991px) {
                    margin-bottom: 15px;
                 }
            }
            .more_btn{
                height: 17.1px;
                line-height: 17.1px;
                color: $font_color;
                &:after{
                    top: 2.7px;
                }
            }
            article{
                header{
                    padding-top: 18px;
                    h3{
                        font-size: 13.5px;
                        line-height: 22.5px;
                        color: $header_color;
                        strong{
                            font-weight: 800;
                        }
                    }
                }
            }
        }
    }
}

.articles{
    padding-top: 130px;
    @media only screen and (max-width: 991px) {
        padding-top: 30px;
    }
    div.table_box{
        min-height: 430px;
        width: 100%;
        display: table;
        border: 1px solid $border_color;
        margin-bottom: 57.6px;
        @media only screen and (max-width: 991px) {
            display: flex;
            flex-flow: column;
            margin-bottom: 30px;
            > div{
                align-items: stretch;
                &.desc{
                    order: 1;
                    width: 100%;
                }
                &.small_slider{
                    order: 2;
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                }
            }
        }
        > div{
            //width: 50%;
            height: 100%;
            vertical-align: top;
            display: table-cell;
            &.small_slider{
                width: 47%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
            &.desc{
                width: 53%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
            @media only screen and (max-width: 991px) {
                display: block;
            }
        }
        .desc{
            padding: 20px 36px 20px 0;
            .desc_top{
                padding-left: 40px;
                position: relative;
                margin-bottom: 29px;
                &:before{
                    width: 3px;
                    height: 100%;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;

                }
            }
            .left_pd{
                padding-left: 36px;
            }

        }
        .small_slider{
            position: relative;
           > ul{
                @include fill;
              >  li{
                    @include fill;
                    .image{
                        @include fill;
                        @include bg_def_pos;
                        background-size: cover;
                        .slider_desc{
                            @include fill;
                            text-align: center;
                            @include vertical;
                            background: rgba(0,0,0,0);
                            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.6)));
                            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                            background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                            background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
                            > div{
                                width: 100%;
                                .default_font{
                                    font-family: $special_font;
                                    margin-bottom: 15px;
                                    h2{
                                        color: $white;
                                        strong{
                                            font-weight: 800;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .small_slider_nav{
                padding:30px 0 20px 0;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;

                .slider_dots{
                    padding-top: 18px;
                    ul{
                        padding-left: 27px;
                        @media only screen and (max-width: 991px) {
                           display: none;
                        }
                        li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 16px;
                            width: 16px;
                            height: 0;
                            padding-bottom:12px;
                            border: 2px solid $white;
                            border-radius: 50%;
                            cursor: pointer;
                            &:last-child{
                                margin-right: 0;
                            }
                            &.on{
                                background: $white;
                            }
                        }
                    }
                }
                .arrs{
                    text-align: right;
                    padding-right: 12px;
                    @media only screen and (max-width: 991px) {
                        padding-right: 0;
                    }
                    li{
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 15px;
                        img{
                            width: 43.2px;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 50%;
                            margin-right: 0;
                            &:first-child{
                                text-align: left;
                            }
                            &:last-child{
                                text-align: right;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        &.stomatology{
            .desc_top{
                &:before{
                    background:$accent_color3;
                }
            }
        }
        &.service{
            .desc_top{
                &:before{
                    background:$accent_color4;
                }
            }
        }
    }
}

.fast_links{
    padding: 0 0px;
    text-align: center;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: 1200px) {
        padding: 0;
    }
    ul{
        @media only screen and (max-width: 991px) {
            margin: 0 -15px;
        }
        li{
            display: inline-block;
            vertical-align: top;
            width: 19%;
            margin-left: 6.2%;
            @media only screen and (max-width: 991px) {
                margin: 0;
                padding: 0 15px;
                width: 25%;
            }
            @media only screen and (max-width: 991px) {
                width: 50%;
                margin-bottom: 30px;
            }
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
            &:first-child{
                margin-left: 3.1%;
                @media only screen and (max-width: 991px) {
                    margin: 0;
                }
                @media only screen and (max-width: 600px) {
                    margin-bottom: 30px;
                }
            }
            &.find{
                .box{
                    > div{
                        div{
                            &.icon{
                                background-color: $accent_color1;
                            }
                        }
                    }
                }
            }
            &.system{
                .box{
                    > div{
                        div{
                            &.icon{
                                background-color: #5aa9e5;
                            }
                        }
                    }
                }
            }
            &.forum{
                .box{
                    > div{
                        div{
                            &.icon{
                                background-color: #3884e4;
                            }
                        }
                    }
                }
            }
            &.improve{
                .box{
                    > div{
                        div{
                            &.icon{
                                background-color: #1c4fce;
                            }
                        }
                    }
                }
            }
            .box{
                border: 1px solid $border_color;
                text-align: left;
                background: $white;
                @include anim;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
                &:hover{
                    transform: scale(1.05);
                    @include shadow;
                }
                > div{
                    padding: 22.5px 0;
                    div{
                        display: inline-block;
                        vertical-align: middle;
                        @media only screen and (max-width: 991px) {
                            display: block;
                        }
                        &.icon{
                            width: 72px;
                            height: 0;
                            padding-bottom: 72px;
                            @include bg_def_pos;
                            background-size: cover;
                            position: relative;
                            left: -36px;
                            @media only screen and (max-width: 991px) {
                               left: 0;
                               margin: 0 auto 20px auto;
                             }
                        }
                        &.desc{
                            color: $header_color;
                            font-size: 16.2px;
                            line-height: 28.3px;
                            font-family: $special_font;
                            width: calc(100% - 80px);
                            padding-right: 20px;
                            @media only screen and (max-width: 1200px) {
                                font-size: 15px;
                                line-height: 27px;
                                margin-left: -15px;
                            }
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                padding-right: 10px;
                                padding-left: 10px;
                                text-align: center;
                                margin-left: 0;
                            }
                            p{
                                font-weight: 800;
                            }
                        }
                    }
                }
            }
        }
    }
}

.article_choice{
    display: table;
    width: 100%;
    border: 1px solid $border_color;
    table-layout: fixed;
    border-bottom: none;
    background: $white;
    @media only screen and (max-width: 991px) {
        display: block;
    }
    > div{
        display: table-row;
        width: 100%;
        @media only screen and (max-width: 991px) {
            display: block;
        }
        > div{
            padding: 0 25.2px;
            width: 50%;
            height: 100px;
            display: table-cell;
            vertical-align: middle;
            border-right: 1px solid $border_color;
            border-bottom: 1px solid $border_color;
            background: $white;
            @include anim;
            @media only screen and (min-width: 991px) {
                &:hover{
                    @include shadow;
                    transform: scale(1.1);

                }
            }
            @media only screen and (max-width: 991px) {
                display: block;
                width: 100%;
                padding-top: 20px;
                border-right: none;
            }
            &:nth-child(2n), &:last-child{
                border-right: none;
            }
        }
    }
    .date_header{
        margin-bottom: 0px;
        h3{
            font-size: 13.5px;
            line-height: 21.6px;
        }
    }
}

.newspaper{
    padding-top: 130px;
    margin-top: -64px;
    background-position: left top;
    background-repeat: no-repeat;
    background-image: url('./../img/news_paper_bg.jpg');
    background-size: cover;
    @media only screen and (max-width: 1500px) {
        padding-top: 130px;
    }
    @media only screen and (max-width: 991px) {
        margin-top: 0;
        padding-top: 30px;
    }
}

@media only screen and (max-width: 991px) {
    .side_left, .side_right{
        padding: 0 15px !important;
    }
}

.newspaper{
    position: relative;
    > div{
        display: inline-block;
        vertical-align: top;
        &.newspaper_left{
            width: 50%;
            @media only screen and (max-width: 1500px) {
                width: 40%;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                text-align: center;
            }
            header{
                margin-bottom: 64px;
                color:$white;
                font-size: 33.3px;
                line-height: 42.3px;
                font-family: $special_font;
                padding-left: 111.6px;
                @media only screen and (max-width: 1500px) {
                    font-size: 31px;
                    line-height: 41px;
                }
                @media only screen and (max-width: 1200px) {
                    padding-left: 80px;
                }
                @media only screen and (max-width: 991px) {
                    padding-left: 15px;
                    padding-right: 15px;
                    margin-bottom: 30px;
                }
                strong{
                    font-weight: 800;
                }
                h2{
                    font-size:50.4px;
                    line-height: 59.4px;
                    color: $header_color;
                    margin-bottom: 10px;
                    @media only screen and (max-width: 1500px) {
                        font-size: 43px;
                         line-height: 53px;
                    }
                }
            }
            .image{
                width: 58%;
                padding-left: 14%;
                position: absolute;
                bottom: 0%;
                left: -8%;
                @media only screen and (max-width: 1500px) {
                    width: 56%;
                    padding-left: 14%;
                    position: absolute;
                    bottom: 0%;
                    left: -11%;
                }
                @media only screen and (max-width: 991px) {
                    display: none;
                }
                div{
                    @include bg_def_pos;
                    background-size: contain;
                    width: 100%;
                    height: 0;
                    padding-bottom: 45.5%;
                }
            }
        }
        &.newspaper_right{
            width: 50%;
            margin-bottom: 70px;
            @media only screen and (max-width: 1500px) {
                width: 60%;
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
                width: 100%;
            }
            .desc{
                padding-right: 52.2px;
                padding-left: 77.4px;
                @media only screen and (max-width: 991px) {
                    padding: 0;
                }
                .text-right{
                    margin: 64px 0;
                    @media only screen and (max-width: 1500px) {
                        margin: 24px 0;
                    }
                    .more_btn{
                        color: $font_color2;
                    }
                }
            }
        }
    }
}

.footer{
    &.footer2{
        background: $theme_color;
        border-top: 1px solid $border_color;;
    }
    div{
        &.newsletter{
            margin:63px 0 45px 0;
            background: $accent_color1;
            height: 108px;
            display: table;
            width: 100%;
            background-repeat:no-repeat;
            background-position: right bottom -27px;
            background-image:url('./../img/newsletter_img.png');
            @media only screen and (max-width: 1500px) {
                margin: 50px 0 30px 0;
                height: 100px;
            }
            @media only screen and (max-width: 991px) {
                display: block;
                height: auto;
                padding: 30px 15px;
            }
            > div{
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                @media only screen and (max-width: 991px) {
                    display: block;
                }
                &:first-child{
                    color: $white;
                    font-size: 18px;
                    line-height: 18px;
                    font-family: $special_font;
                    width: 387px;
                    padding: 0 49.5px;
                    @media only screen and (max-width: 1200px) {
                        width: 370px;
                    }
                    strong{
                        font-weight: 800;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 15px;
                    }
                }
                &:last-child{
                    width: calc(100% - 387px);
                    padding-right: 52.2px;
                    @media only screen and (max-width: 1200px) {
                        width: calc(100% - 370px);
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding-right: 0;
                    }
                    form{
                        border-bottom: 1px solid $accent_color2;
                        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            font-family: $special_font;
                            color: #7e97b2;

                          }
                          ::-moz-placeholder { /* Firefox 19+ */
                            font-family: $special_font;
                            color: #7e97b2;
                          }
                          :-ms-input-placeholder { /* IE 10+ */
                            font-family: $special_font;
                            color: #7e97b2;
                          }
                          :-moz-placeholder { /* Firefox 18- */
                            font-family: $special_font;
                            color: #7e97b2;
                          }
                    }
                    input{
                        height:45px;
                        vertical-align:top;
                        outline: none;
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;
                        &.n_input{
                            font-size: 13.5px;
                            color: #7e97b2;
                            width: calc(100% - 198px);
                            @media only screen and (max-width: 1200px) {
                                font-size: 10.5px;
                            }
                            @media only screen and (max-width: 991px) {
                                width: calc(100% - 22px);
                                font-size: 8.6px;
                            }
                        }
                        &.n_submit{
                            width: 198px;
                            font-family: $special_font;
                            font-size: 9px;
                            color: $white;
                            font-weight:800;
                            text-transform:uppercase;
                            background-position: right center;
                            background-repeat:no-repeat;
                            background-image:url('./../img/more_arr1.png');
                            @media only screen and (max-width: 991px) {
                                width: 22px;
                                font-size: 0;
                            }
                        }
                    }
                }
            }
        }
        &.fast_contact{
            font-size: 13.5px;
            line-height: 27px;
            color: #5e6b80;
            margin-bottom: 57.6px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 44px;
                font-size: 13px;
                line-height: 28px;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
                margin-bottom: 0;
            }
            a{
                color: inherit;
                @include anim;
                &:hover{
                    color: $accent_color2;
                }
            }
            span{
                font-family: $special_font;
                font-size: 11px;
                text-transform: uppercase;
                color: $font_color2;
                font-weight: 800;;
            }
        }
        &.footer_bottom{
            padding: 42px 0;
            border-top: 1px solid $border_color;
            @media only screen and (max-width: 1200px) {
                //padding: 20px 0;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    img{
                        margin-left: 18px;
                        @media only screen and (max-width: 1200px) {
                            max-width: 39px;
                            margin-left: 5px;
                        }
                    }
                }
            }
            nav{
                margin-top: -15px;
                @media only screen and (max-width: 1200px) {
                    margin-top: -18px;
                }
                @media only screen and (max-width: 991px) {
                    padding-top: 15px;
                    margin-top: 0;
                }
                ul{
                    li{
                        margin-right: 42px;
                        @media only screen and (max-width: 1200px) {
                            margin-right: 30px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            margin-bottom: 10px;
                            width: 100%;
                            text-align: center;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        div{
                            a{
                                font-size: 11.5px;
                                line-height: 30px;
                                font-weight: 800;
                                color: $font_color2;
                                font-family: $special_font;
                                @include anim;
                                &:hover{
                                    color: $accent_color2;
                                }
                            }
                        }
                    }
                }
            }
        }
        .to_up{
            margin-top: 20px;
            display: inline-block;
            cursor: pointer;
            @media only screen and (max-width: 991px) {
                margin-bottom: 20px;
            }
        }
    }
}

.top{
    background-position: left top;
    background-repeat: repeat-x;
    //background-image: url('./../img/top_bg.jpg');
    //background-color:#e9ecf1;
    padding: 19.8px 0 19.8px 0;
    min-height: 140px;
    margin-bottom: 27px;
    background: rgba(233,236,241,0.5);
    background: -moz-linear-gradient(top, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(233,236,241,0.5)), color-stop(15%, rgba(239,241,245,0)), color-stop(52%, rgba(255,255,255,0)), color-stop(85%, rgba(240,242,245,0)), color-stop(100%, rgba(233,236,241,0.5)));
    background: -webkit-linear-gradient(top, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    background: -o-linear-gradient(top, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    background: -ms-linear-gradient(top, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    background: linear-gradient(to bottom, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ecf1', endColorstr='#e9ecf1', GradientType=0 );
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    &.top2{
        background: $theme_color2;
        min-height: 0;
        border-top: 1px solid $border_color;
        margin-bottom: 0;
    }
    &.top3{
        .page_header{
            text-align: left;
            margin-bottom: 10px;
            padding-top: 15px;
            p{
                margin-bottom: -10px;
            }
        }
       .top_desc{
           text-align: left;
       }
    }
    div{
        &.nav_way{
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    &:last-child{
                        a{
                            color: $accent_color1;
                            font-weight: 700;
                            &:after{
                                display: none;
                            }
                        }
                    }
                    a{
                        color: $font_color;
                        font-size: 10.8px;
                        line-height: 15.3px;
                        position: relative;
                        &:after{
                            content: '-';
                            margin: 0 8.1px;
                        }
                    }
                }
            }
        }
        &.top_desc{
            text-align: center;
            font-size: 12.6px;
            line-height: 24.3px;
            color: $font_color;
        }
    }
    .page_header{
        div.default_font{
            h1{
                //font-size: 31.5px;
                font-size: 28px;
                line-height:44.1px;
            }
        }
    }
}

.toparticle {
    background: rgba(233,236,241,0.5);
    background: -moz-linear-gradient(top, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(233,236,241,0.5)), color-stop(15%, rgba(239,241,245,0)), color-stop(52%, rgba(255,255,255,0)), color-stop(85%, rgba(240,242,245,0)), color-stop(100%, rgba(233,236,241,0.5)));
    background: -webkit-linear-gradient(top, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    background: -o-linear-gradient(top, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    background: -ms-linear-gradient(top, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    background: linear-gradient(to bottom, rgba(233,236,241,0.5) 0%, rgba(239,241,245,0) 15%, rgba(255,255,255,0) 52%, rgba(240,242,245,0) 85%, rgba(233,236,241,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ecf1', endColorstr='#e9ecf1', GradientType=0 );
    padding: 22px 0 22px 0;
    min-height: 140px;
    margin-bottom: 80px;
}

.square_list, .el_list{
    ul{
        margin: 0 -25px;
        @media only screen and (max-width: 1200px) {
            margin: 0 -15px;
        }
        li{
            padding: 0 25px;
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            margin-bottom: 45px;
            @media only screen and (max-width: 1200px) {
               padding: 0 15px;
               margin-bottom: 30px;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                margin-bottom: 30px;
            }
            &:hover{
                .img{
                    transform: scale(1.1);
                }
            }
            .image{
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 58.3%;
                @include gradient1;
                overflow: hidden;
                .desc{
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 0 38px 33px 38px;
                    color: rgba(255,255,255,0.6);
                    font-size: 11.5px;
                    line-height: 21.5px;
                    font-family: $special_font;
                    z-index: 2;
                    h2{
                        font-size: 22px;
                        line-height: 30px;
                        color: $white;
                    }
                }
                .img{
                    z-index: -1;
                    @include fill;
                    @include bg_def_pos;
                    @include anim;
                    background-size: cover;
                }
            }
        }
    }
}

.gallery{
    padding-bottom: 27px;
}

.faq{
    padding-bottom: 52.2px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    .toggle_list{
        ul{
            li{
                cursor: pointer;
                border: 1px solid $border_color;

                width: 100%;
                //padding: 0 99px 0 18px;
                padding: 0 18px 0 18px;
                @include anim;
                background-position: right 18.9px top 21.6px;
                background-repeat: no-repeat;
                background-image: url('./../img/plus.png');
                @include anim;
                margin-bottom: 18px;
                color: $font_color2;
                @media only screen and (max-width: 991px) {
                        padding: 0 45px 0 18px;
                }
                div, button{
                    &.toggle_top{
                        text-align: left;
                        background-color: transparent;
                        width: 100%;
                        border: 0;
                        width: 100%;
                        padding: 18px 0;
                    }
                    &.toggle_bottom{
                        padding: 0 0 18px 0;
                        display: none;
                    }
                }
                &.active{
                    border: 1px solid $accent_color2;
                    background-image: url('./../img/minus.png');
                }
            }
        }
    }
}

.faqarticle {
    padding-bottom:10px;
    .toggle_list {
        ul {
            list-style-type: none;
            padding-left: 0px;

        }
    }
}

.contact{
    padding-bottom: 25px;
    .container{
        div.row{
            margin: 0 -35px;
            > div{
                padding: 0 35px;
            }
        }
    }
    .contact_top{
        padding-bottom: 25px;
    }
    .contact_data{
        @include shadow;
        padding: 45px;
        @media only screen and (max-width: 991px) {
            padding: 30px;
        }
        .contact_list{
            margin-bottom: 45px;
            ul{
                li{
                    padding-left: 37px;
                    color: $font_color;
                    background-repeat: no-repeat;
                    background-position: left center;
                    min-height: 22px;
                    margin-bottom: 24px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.phone{
                        background-image:url('./../img/c_icon1.png');
                    }
                    &.fax{
                        background-image:url('./../img/c_icon2.png');
                    }
                    &.adres{
                        background-image:url('./../img/c_icon3.png');
                        background-position: left 3px center;
                    }
                    a{
                        @include anim;
                        &:hover{
                          color: $accent_color2;
                        }
                    }
                    a,strong{
                        color: $accent_color1;
                        font-weight: 600;
                    }
                }
            }
        }
        .epuap{
            background-position: left top;
            background-repeat: no-repeat;
            background-image: url('./../img/epuap.png');
            font-weight: 600;
            color: $font_color2;
            p{
                font-weight: inherit;
                padding-top: 35px;
            }
        }
    }
    .contact_map{
        width: 100%;
        height: 0;
        padding-bottom: 79%;
        position: relative;
        iframe{
            @include fill;
            border: none;
        }
    }
    .go_to_form_banner{
        margin-top: 55px;
        height: 193.5px;
        width: 100%;
        @include bg_def_pos;
        background-size: cover;
        background-image: url('./../img/go_to_form_banner.png');
        font-size: 22.5px;
        line-height: 27px;
        color: $white;
        font-family: $special_font;
        font-weight: 800;
        padding: 40.5px 0 0 49.5px;
        @media only screen and (max-width: 1200px) {
            text-align: center;
            margin-bottom: 30px;
        }
        p{
            font-weight: inherit;
            margin-bottom: 35px;
        }
    }
}


.contact_form, .register{
    .row{
        margin: 0 -30px;
        > div{
            padding: 0 30px;
        }
    }
    .holder{
        margin-bottom: 11px;
        &:last-child{
            margin-bottom: 0;
        }

    }
    input{
        height: 49.5px;
        padding: 0 24.3px;
        width: 100%;
        &.default_input{
            background: #f6fafb;
            border: 1px solid #cfdee2;
            border-radius: 5px;
            font-weight: 600;
            color: #5e6b80;
            font-family: $special_font;
            font-size: 12.6px;
            @media only screen and (max-width: 991px) {
                font-size: 10px;
            }
        }
    }
    .default_text_area{
        background: #f6fafb;
        border: 1px solid #cfdee2;
        border-radius: 5px;
        font-weight: 600;
        color: #5e6b80;
        font-family: $special_font;
        font-size: 12.6px;
        padding:18px 24.3px;
        min-height: 124.2px;
        outline: none;
        resize: none;
        width: 100%;
        @media only screen and (max-width: 991px) {
            margin-bottom: 15px;
        }
    }
    .rules{
        ul{
            li{
                padding: 28.8px 25.2px;
                background: #f9f9f1;
                border-radius: 5px;
                margin-bottom: 18px;
                &:last-child{
                    margin-bottom: 0;
                }
               > div{
                    display: inline-block;
                    vertical-align: top;
                    &.check_box_holder{
                        width: 18px;
                        .checkbox_image_box{
                            margin-top: 2px;
                            width: 100%;
                            height: 18px;
                            position: relative;
                            > div{
                                background-repeat: no-repeat;
                                background-position: center;
                                @include fill;
                                background-color: $white;
                                border: 1px solid #cdcdab;
                                &.yes{
                                    &.on{

                                        background-image: url('./../img/rule_check.png');;
                                    }
                                }
                            }
                        }
                    }
                    &.desc{
                        width: calc(100% - 18px);
                        padding-left: 18px;
                        color: #666661;
                        font-size:12.6px;
                        line-height: 25.2px;
                        font-family: $special_font;
                        font-weight: 600;
                        p{
                            font-weight: inherit;
                        }
                    }
                }
            }
        }
    }
}

.register_box{
    .section_header{
        margin-bottom: 0px;
    }
    .row{
        margin: 0 -20px !important;
        margin-bottom: 30px;
        > div{
            padding: 0 20px !important;
            margin-bottom: 30px;
        }
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #90a1ae;
        font-weight: 600;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #90a1ae;
        font-weight: 600;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #90a1ae;
        font-weight: 600;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #90a1ae;
        font-weight: 600;
      }
    label{
        margin-bottom: 10px;
        color: #0c2a5f;
    }
    .chosen-container-single {
        .chosen-single{
            box-shadow: none;
        }
    }
    .check_box_large{
        height: 55px;
        border: 1px solid $border_color;
        border-radius: 5px;
        line-height: 55px;
        padding:0 20px;
        @media only screen and (max-width: 991px) {
            padding: 0 15px;
        }
        > div{
            display: inline-block;
            vertical-align: middle;
            &.check_holder{
                width: 12px;
                .checkbox_image_box{
                    width: 12px;
                    height: 0;
                    padding-bottom: 10px;
                    position: relative;
                    border: 1px solid $border_color;
                    border-radius: 50%;
                    > div{
                        @include fill;
                        border: none;
                        &.yes{
                            @include bg_def_pos;
                            background-size: cover;;
                            &.on{
                               background:#00a097;
                            }
                        }
                    }
                }
            }
            &.check_desc{
                width: calc(100% - 12px);
                padding-left: 15px;
                line-height: 55px;
                font-size: 15px;
                color: #426a89;
                @media only screen and (max-width: 991px) {
                    font-size: 10px;
                }
            }
        }
    }
    .captcha{
        padding: 20px 0;
    }
    .submit_btn{
        margin: 0;
    }
}


.default_submit{
    @include fill;
    opacity: 0;
}
.submit_btn{
    margin: 54px 0;
    color: $white;
    font-family: $special_font;
    font-size: 9.9px;
    line-height: 45px;
    height: 45px;
    background: $accent_color1;
    position: relative;
    text-align: center;
    padding: 0 24.3px;
    @media only screen and (max-width: 991px) {
        margin: 40px 0;
    }
    &.search{
        width: 200px;
        margin: 35px 0 40px auto !important;
        display: block;
        height: 35px;
        line-height:35px;
        @media only screen and (max-width: 991px) {
            width: 100%;
            margin: 15px 0 !important;
        }
        p{
            &:after{
                top: 7px;
                width: 21px;
                height: 21px;
                background-image: url('./../img/search_icon2.png');
            }
        }
    }
    &:hover{
        p{
            &:after{
                right: -10px;
            }
        }
    }
    p{
        font-weight: 800;
        position: relative;
        display: inline-block;
        padding-right: 40px;
        &:after{
            position: absolute;
            content: '';
            width: 20px;
            height: 12px;
            top: 19px;
            right: 0;
            @include bg_def_pos;
            background-image: url('./../img/more_arr1.png');
            @include anim;
        }
    }
}

.office_contact{
    .office_box{
        line-height: 19.8px;
        margin-bottom: 35px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 0;
        }
        header{
            color: #0c2a5f;
            margin-bottom: 20px;
            @media only screen and (max-width: 1200px) {
                margin-bottom: 15px;
            }
            h3{
                font-size: 18.9px;
                line-height: 27.9px;
                @media only screen and (max-width: 1500px) {
                    font-size: 18px;
                    line-height: 28px;
                }
                @media only screen and (max-width: 1200px) {
                    font-size: 17px;
                    line-height: 27px;
                }
                strong{
                    font-weight: 600;
                }
            }
        }
        p{
            color: #0c2a5f;
            margin-bottom: 13.5px;
            &:last-child{
                margin-bottom: 0;
            }
            a{
                color: #0c2a5f;;
                span{
                    color: #a6afc0;;
                }
            }
        }
        a.media_link{
            color: #4fccc3;
            text-decoration: underline;
            font-weight: 700;
        }
    }
}

.contact_staff{
    .office_contact{
        ul{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
            grid-gap: 25px;
            //grid-auto-rows: minmax(330px, auto);
            grid-auto-flow: dense;
            padding: 10px;
           // margin: 0 -54px;
           @media only screen and (max-width: 991px) {
            display: block;
           }
            li{
               // display: inline-block;
               // vertical-align: top;
               // width: 33.33%;
               // padding: 0 54px;
                //margin-bottom: 18px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                }
                &:first-child{
                    grid-column-end: span 1;
                    grid-row-end: span 4;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

.element_list{
    .section_header{
        margin-bottom: 20px;
    }
    .desc{
        line-height: 27px;
        font-size: 12.7px;
    }
    .el_list{
        text-align: center;
        ul{
            margin: 0 -21px;
            @media only screen and (max-width: 1200px) {
                margin: 0 -15px;
            }
            li{

                padding:0 21px;
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 1200px) {
                    padding: 0 15px;
                }
            }
        }
    }
    &.no_image{
        padding-bottom: 20px;
        @media only screen and (max-width: 991px) {
            padding-bottom: 0;
        }
        .el_list{
            ul{
                li{
                    margin-bottom: 40px;
                    width: 25%;
                    @media only screen and (max-width: 991px) {
                        width: 50%;
                    }
                    @media only screen and (max-width: 600px) {
                        width: 100%;
                    }
                    article{
                        background: $theme_color;
                        border: 1px solid $border_color;
                        height: 205px;
                        width: 100%;
                        @include vertical;
                        color: $accent_color1;
                        @include anim;
                        &:hover{
                            transform: scale(1.05);
                            @include shadow;
                        }
                        div{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.image{
        padding-bottom: 20px;
        .el_list{
            padding-top: 50px;
            @media only screen and (max-width: 991px) {
                padding-top: 30px;
            }
            .desc{
                padding: 0 38px 60px 38px;
                h2{
                    font-size:27px;
                    text-shadow: 1px 1px 10px #000000;
                }
            }
        }
    }
    .split_view{
        padding-top: 40px;
        @media only screen and (max-width: 991px) {
            padding-top: 20px;
        }
        /*
        .list{
            &.image_list{
                ul{
                    li{
                        //margin-bottom: 50px;
                        margin-bottom: 27px;
                        @include anim;
                        &:hover{
                            transform: scale(1.05);
                        }
                        article{
                            padding: 0;
                            border: none;
                            display: table;
                            width: 100%;
                            //min-height: 240px;
                            min-height: 108px;
                            border:1px solid #eeeeee;
                            padding: 13.5px;
                            @media only screen and (max-width: 991px) {
                                display: block;
                            }gulp gulpwatch
                            &:hover{
                                box-shadow: none;
                                .desc{
                                    header{
                                        h3{
                                            color: $accent_color2;
                                        }
                                    }
                                }
                            }
                            > div{
                                display: table-cell;
                                vertical-align: top;
                                height: 100%;
                                @media only screen and (max-width: 991px) {
                                    display: block;
                                }
                                &.image{
                                    width: 108px;
                                    @include bg_def_pos;
                                    background-size: cover;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                        height: 0;
                                        padding-bottom: 100%;
                                        margin-bottom: 10px;
                                    }
                                }
                                &.desc{
                                    width: calc(100% - 108px);
                                    padding-left: 36px;
                                    position: relative;
                                    line-height: 23.4px;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                        padding-left: 0;
                                    }
                                    header{
                                        margin-bottom: 9px;
                                        h3{
                                            @include anim;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 1;
                                            -webkit-box-orient: vertical;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                    p{
                                        display: -webkit-box;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                    .more_btn2{
                                        position: absolute;
                                        bottom: 0;
                                        left: 36px;
                                        display: none;
                                        @media only screen and (max-width: 991px) {
                                            left: 0;
                                            position: relative;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ul{
                li{
                    margin-bottom: 30px;
                    article{
                        padding:20px 27px;
                        border: 1px solid $border_color;
                        color: $font_color;
                        @include anim;
                        &:hover{
                            @include shadow;
                        }
                        .date_header{
                            margin-bottom: 23px;
                        }
                        .desc{
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }*/
    }
}
.paggination_box{
    height: auto !important ;
    display: block !important;
    border: none !important;
    text-align: right;
    margin-bottom: 0 !important;
}
.pagination{
    margin: 54px 0;
    @media only screen and (max-width: 991px) {
        padding: 30px;
        margin: 0;
    }
    > li{
        > a{
            border: 1px solid #dbe2eb;
            color: #10324f;
        }
    }
    > .active{
        > a{
            background: #4fccc3;
            border: 1px solid #4fccc3;
        }
    }
    ul{
       li{
            display:inline-block;
            vertical-align: top;
            ul{
                li{
                    &.square{
                        border-right: none;
                        &:first-child{
                            border-left: none;
                        }
                    }
                }
            }
            &.square{
                width: 43px;
                height: 43px;
                line-height: 43px;
                text-align: center;
                border: 1px solid $border_color;
                position: relative;
                .on{
                    a{
                        background: #4fccc3;
                        color: $white;
                    }
                }
                &:hover{
                    a{
                        background: #4fccc3;
                        color: $white;
                    }
                }
                a{
                    @include fill;
                    @include anim;
                    color: $accent_color1;
                }
            }
        }
    }
}

section.download{
    .filters{
        margin-bottom: 56.7px;
        @media only screen and (max-width: 991px) {
            .row{
                > div{
                    margin-bottom: 15px;
                }
            }
        }
        .text-right{
            a{
                font-family: $special_font;
                color: $font_color;
                font-size: 12.5px;
                line-height: 12.5px;
                text-decoration: underline;
                font-weight: 600;
            }
        }
    }
    form{
        div.holder{
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #90a1ae;
                font-weight: 600;
              }
              ::-moz-placeholder { /* Firefox 19+ */
                color: #90a1ae;
                font-weight: 600;
              }
              :-ms-input-placeholder { /* IE 10+ */
                color: #90a1ae;
                font-weight: 600;
              }
              :-moz-placeholder { /* Firefox 18- */
                color: #90a1ae;
                font-weight: 600;
              }
        }
        label{
            font-size: 17.1px;
            color: #0c2a5f;
            font-weight: 700;
            font-family: $special_font;
            width: 100%;
            margin-bottom: 9px;
        }
        .holder{
            margin-bottom: 13.5px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
            }
        }
        input{
            outline: none;
            &.input{
                width: 100%;
                height: 49.5px;
                border: 1px solid $border_color;
                border-radius: 5px;
                -webkit-box-shadow: 0px 0px 21px 11px rgba(233,237,242,0.6);
                -moz-box-shadow: 0px 0px 21px 11px rgba(233,237,242,0.6);
                box-shadow: 0px 0px 21px 11px rgba(233,237,242,0.6);
                font-family: $special_font;
                color: #90a1ae;
                font-size: 11.7px;
                font-weight: 600;
                padding: 0 18px;
                @media only screen and (max-width: 991px) {
                    font-size: 10px;
                }
            }
        }
        .submit_btn{
            margin: 31.5px 0 0 0;
        }

    }
}

.login{
    .go_to{

        p{
            font-weight: 700;
            padding-right: 60px;
            font-size: 12.5px;
            font-weight: 700;
            color: $font_color;
            font-family: $special_font;
            display: inline-block;
            @media only screen and (max-width: 991px) {
                padding-right: 0;
            }
        }
        a{
            text-decoration: underline;
        }
    }
    background: $theme_color2;
    padding: 30px 0 80px 0;
    @media only screen and (max-width: 1200px) {
        padding: 30px 0 50px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    .text-right{
        padding-top: 30px;
        a{
            font-size: 12.5px;
            font-weight: 700;
            color: $font_color;
            font-family: $special_font;
            display: inline-block;
        }
    }
    form{
        div.holder{
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #90a1ae;
                font-weight: 600;
              }
              ::-moz-placeholder { /* Firefox 19+ */
                color: #90a1ae;
                font-weight: 600;
              }
              :-ms-input-placeholder { /* IE 10+ */
                color: #90a1ae;
                font-weight: 600;
              }
              :-moz-placeholder { /* Firefox 18- */
                color: #90a1ae;
                font-weight: 600;
              }
        }
        label{
            color: #0c2a5f;
            font-weight: 700;
            font-family: $special_font;
            width: 100%;
            margin-bottom: 10px;
        }
        .holder{
            margin-bottom: 15px;
        }
        input{
            outline: none;
            &.input{
                width: 100%;
                height: 55px;
                border: 1px solid $border_color;
                border-radius: 5px;
                -webkit-box-shadow: 0px 0px 21px 11px rgba(233,237,242,0.6);
                -moz-box-shadow: 0px 0px 21px 11px rgba(233,237,242,0.6);
                box-shadow: 0px 0px 21px 11px rgba(233,237,242,0.6);
                font-family: $special_font;
                color: #90a1ae;
                font-size: 13px;
                font-weight: 600;
                padding: 0 20px;
            }
        }
        .submit_btn{
            margin: 35px 0 0 0;
        }

    }
}

.login_box{
    padding: 60px;
    background: $white;
    @include shadow;
    @media only screen and (max-width: 991px) {
        padding: 30px 15px;
    }
    header{
        text-align: center;
        font-family: $special_font;
        margin-bottom: 24px;
        h3{
            font-size: 31px;
            line-height: 41px;
            color: #0c2a5f;
        }
    }
}

.my_practice{
    margin-bottom: 15px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 60px;
    }
    @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
    }
    .container{
        > .row{
            margin: 0 -30px;
            @media only screen and (max-width: 1200px) {
                margin: 0 -15px;
            }
            > div{
                padding: 0 30px;
                @media only screen and (max-width: 1200px) {
                    padding: 0 15px;
                }
            }
        }
    }
    div{
        header.section_header{
            margin-bottom: 15px;
            h2{
                font-size: 22px;
            }
        }
        &.posts{
            ul{
                li{
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.more{
            @media only screen and (max-width: 991px) {
                padding-top: 30px;
            }
            .small_banner{
                border-radius: 5px;
                height: 180px;
                width: 100%;
                @include bg_def_pos;
                font-size: 18.9px;
                line-height: 22.5px;
                font-family: $special_font;
                color: $white;
                position: relative;
                @include anim;
                background-size: cover;

                .cover{
                    @include fill;
                    @include gradient1;
                }
                &:hover{
                    opacity: 0.8;
                }
                > div{
                    padding:0 27px;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 27px;
                    .row{
                        display: table;
                        @media only screen and (max-width: 991px) {
                            display: block;
                        }
                        > div{
                            float: none;
                            display: table-cell;
                            vertical-align: middle;
                            @media only screen and (max-width: 991px) {
                                display: block;
                                text-align: center;
                                .more_btn3{
                                    margin-top: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.more_articles{
            padding-top: 18px;
            .last_articles_box{
                padding: 23.4px 38px;
                border: 1px solid $border_color;
                border-bottom: 2px solid $border_color;
                border-radius: 5px;
                header{
                    font-family: $special_font;
                    margin-bottom: 20px;
                    h3{
                        font-size: 800;
                        color: #023671;
                        font-size: 18.9px;
                        line-height: 27.9px;
                    }
                }
                ol{
                    padding-left: 0;
                    counter-reset: item;
                    li{
                        padding-bottom: 17px;
                        margin-bottom: 17px;
                        border-bottom: 1px solid $border_color;
                        display: block;
                        padding-left: 18px;
                        position: relative;
                        &:last-child{
                            margin-bottom: 0;
                            padding-bottom: 0;
                            border: none;
                        }
                        &:before {
                            display: inline-block;
                            content: counter(item) ". ";
                            counter-increment: item;
                            position: absolute;
                            top: 0;
                            left: 0;
                            font-weight: 700;
                            font-size: 14.4px;
                          }
                        a{
                            text-decoration: none;
                            font-size: 13.5px;
                            line-height: 20.7px;
                            color: $font_color;
                        }
                    }
                }
            }
        }
    }
}
.download_boxx{
    margin-bottom: 50px;
}
.download{
    div.desc{
        font-size:12.5px;
        line-height: 26px;
        .section_header{
            padding-top: 9px;
            h2{
                font-size: 19.6px;
                line-height: 28.6px;
            }
        }
    }

    div.download_list{
        padding-top: 45px;
        @media only screen and (max-width: 1200px) {
            padding-top: 30px;
        }
        &.d_list2{
            padding-top: 0;
            .pioromycalendar2_js_ajax_box_doc{
                ul{
                    li{

                        a{
                            padding-left: 72px;
                            background-repeat: no-repeat;
                            background-position: left 19.8px center;
                            background-image: url('./../img/d_icon2.png');
                            border: 1px solid $border_color;
                            @media only screen and (max-width: 991px) {
                                background-image: none;
                                padding-left: 0;
                            }
                            .download_box{
                                min-height: 0;
                                padding: 13.5px 117px 13.5px 0px;
                                border: none;
                                @media only screen and (max-width: 991px) {
                                    padding: 13.5px 63px 13.5px 13.5px;
                                }
                            }
                        }
                    }
                }
            }
            > ul{
                li{

                    a{
                        padding-left: 72px;
                    background-repeat: no-repeat;
                    background-position: left 19.8px center;
                    background-image: url('./../img/d_icon2.png');
                    border: 1px solid $border_color;
                    @media only screen and (max-width: 991px) {
                        background-image: none;
                        padding-left: 0;
                    }
                        .download_box{
                            min-height: 0;
                            padding: 13.5px 117px 13.5px 0px;
                            border: none;
                            @media only screen and (max-width: 991px) {
                                padding:  13.5px 63px 13.5px 13.5px;
                            }
                        }
                    }
                }
            }
        }
        ul{
            li{
                @include anim;
                margin-bottom: 18px;
                &:hover{
                    transform: scale(1.05);
                    @include shadow;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    display: block;
                    .download_box{
                        min-height: 85.5px;
                        padding: 18.9px 117px 18.9px 18.9px;
                        border: 1px solid $border_color;
                        background-repeat: no-repeat;
                        background-position: right 22.5px center;
                        background-repeat: no-repeat;
                        background-image: url('./../img/d_icon1.png');
                        background-size: 21.6px;
                        @include anim;

                        header{
                            //margin-bottom: 15px;
                            font-family: $special_font;
                            p{
                                line-height: 18.9px;
                                color: $font_color;;
                                font-weight: 600;
                                font-size: 14px;
                                strong{
                                    color: #0c2a5f;
                                }
                            }
                        }
                        .details{
                            //padding-top: 15px;
                            font-size: 11.5px;
                            line-height: 14.4px;
                            font-weight: 600;
                            font-family: $special_font;
                            color: $font_color;
                            p{
                                font-weight: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
}

.universal{
    padding-bottom: 54px;
    .container{
        @media only screen and (max-width: 991px) {
            > div{
                display: flex;
                flex-flow: column;
                > div{
                    align-items: stretch;
                    &.col-lg-3{
                        order: 2;
                    }
                    &.col-lg-9{
                        order: 1;
                    }
                }
            }
        }
    }
    .container-fluid{
        @media only screen and (max-width: 991px) {
            > div{
                display: flex;
                flex-flow: column;
                > div{
                    align-items: stretch;
                    &.col-lg-3{
                        order: 2;
                    }
                    &.col-lg-9{
                        order: 1;
                    }
                }
            }
        }
    }
    .tags_list{
        width: 100%;
        @media only screen and (max-width: 991px) {
            width: 100%;
            padding-left: 0;
        }
        ul{
            display: flex;
            width: 100%;
            flex-flow: row;
            flex-wrap: no-wrap;
            @media only screen and (max-width: 1200px) {
                flex-wrap: wrap;
            }
            @media only screen and (max-width: 991px) {
                display: block;
            }
            li{
                min-height: 36px;
                color: $white;
                font-size: 12.5px;
                background-repeat: no-repeat;
                background-position: left 20px center;
                padding: 5px 17px 5px 55px;
                border: 1px solid $white;
                width: 16.66%;
                @include vertical;
                @media only screen and (max-width: 1200px) {
                    width: 50%;
                }
                p{
                    width: 100%;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.dentist{
                    background-image: url('./../img/tag_icon1.png');
                    background-color: #96aeca;
                }
                &.elearning{
                    background-color: #9dc2b7;
                    background-image: url('./../img/tag_icon2.png');
                }
                &.doctor{
                    background-image: url('./../img/tag_icon1.png');
                    background-color: #b799c7;
                }
                &.stationary{
                    background-color: #c2c09d;
                    background-image: url('./../img/tag_icon3.png');
                }
                p{
                    font-weight: 700;
                }
            }
        }
    }
    > .container{

        > .row{
            margin: 0 -30px;
            @media only screen and (max-width: 1200px) {
                margin: 0 -15px;
            }
            > div{
                padding: 0 30px;
                @media only screen and (max-width: 1200px) {
                    padding: 0 15px;
                }
                &.col-md-4{
                    @media only screen and (max-width: 991px) {
                        display: flex;
                        flex-flow: column;
                         > div, aside{
                            align-items: stretch;
                        }
                        aside{
                            order: 1;
                        }
                        > div{
                            order: 2;
                        }
                     }
                }
            }
        }
    }
    div{
        .last_posts{
            margin-bottom: 30px;
            ul{
                margin-top: -30px;
                li{
                    margin-bottom: 12px;
                    border-bottom: 1px solid $border_color;
                    article{
                        .date_header{
                            font-family: $special_font;
                            p{
                                margin-bottom: 5px;
                            }
                            margin-bottom: 0;
                            h3{
                                font-size: 15px;
                                font-weight: 600;
                                line-height: 26px;
                            }
                        }
                        .more_btn{
                            color: $font_color;
                        }
                    }
                }
            }
        }
        &.desc{
            .image{
                padding-bottom: 400px;
                @include bg_def_pos;
                background-size: contain;
                margin-bottom: 30px;
                @media only screen and (max-width: 991px) {
                    padding-bottom: 100%;
                }
            }
            .default_font{
                font-size: 13.5px;
                line-height: 22.5px;
                h1,h2,h3,h4,h5,h6{
                    color: #023671;
                }
            }
            &.full{
                //padding-right: 75px;
                padding-bottom: 35px;
                div{
                    &.image{
                        height: 0;
                        width: 100%;
                    }
                    &.default_font{
                        width: 100%;
                    }
                }
            }
            &.image_left{
                padding: 20px 0;
                div{
                    display: inline-block;
                    vertical-align: top;
                    &.image{
                        height: 0;
                        width: 50%;
                        @media only screen and (max-width: 991px) {
                           width: 100%;
                        }
                    }
                    &.default_font{
                        width: 50%;
                        padding-left: 50px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding-left: 0;
                        }
                    }
                }
            }
            &.image_right{
                padding: 20px 0;
                @media only screen and (max-width: 991px) {
                    display: flex;
                    flex-flow: column;
                    > div{
                        align-items: stretch;
                        &.image{
                            order: 1;
                        }
                        &.default_font{
                            order: 2;
                        }
                    }
                }
                div{
                    display: inline-block;
                    vertical-align: top;
                    &.image{
                        //padding-bottom: 40%;
                        height: 0;
                        width: 50%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    &.default_font{
                        width: 50%;
                        padding-right: 50px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding-right: 0;
                        }
                    }
                }
            }
            &.image_left_large{
                padding: 20px 0;
                div{
                    display: inline-block;
                    vertical-align: top;
                    &.image{
                        //padding-bottom: 40%;
                        height: 0;
                        width: 60%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    &.default_font{
                        width: 40%;
                        padding-left: 50px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding-left: 0;
                        }
                    }
                }
            }
            &.image_right_large{
                padding: 20px 0;
                @media only screen and (max-width: 991px) {
                    display: flex;
                    flex-flow: column;
                    > div{
                        align-items: stretch;
                        &.image{
                            order: 1;
                        }
                        &.default_font{
                            order: 2;
                        }
                    }
                }
                div{
                    display: inline-block;
                    vertical-align: top;
                    &.image{
                        //padding-bottom: 40%;
                        height: 0;
                        width: 60%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    &.default_font{
                        width: 40%;
                        padding-right: 50px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding-right: 0;
                        }
                    }
                }
            }
            &.image_center{
                padding: 20px 0;
                @media only screen and (max-width: 991px) {
                    display: flex;
                    flex-flow: column;
                    > div{
                        align-items: stretch;
                        &.image{
                            order: 1;
                        }
                        &:first-child{
                            order: 2;
                        }
                        &:last-child{
                            order: 3;
                        }
                    }
                }
                div{
                    display: inline-block;
                    vertical-align: top;
                    &:first-child{
                        padding-right: 50px;
                        @media only screen and (max-width: 991px) {
                            padding-right: 0;
                        }
                    }
                    &:last-child{
                        padding-left: 50px;
                        @media only screen and (max-width: 991px) {
                            padding-left: 0;
                        }
                    }
                    &.image{
                        //padding-bottom: 40%;
                        height: 0;
                        width: 33.33%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    &.default_font{
                        width: 33.33%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
        &.quote{
            margin: 50px 0;
            padding: 45px 45px 0 45px;
            border-top: 1px solid $border_color;
            border-bottom: 1px solid $border_color;
            line-height: 37px;
            color: #023671;
            position: relative;
            @media only screen and (max-width: 991px) {
                padding: 45px 25px 0 25px;
            }
            .icon{
                width: 70px;
                height: 35px;
                @include bg_def_pos;
                background-image: url('./../img/q1.png');
                position: absolute;
                left: 30px;
                top: -17px;
                background-color: $white;
            }
            .author{
                font-size: 16px;
                line-height: 20px;
                color: 023671;
                width: 103%;
                padding: 43px 0;
                background-position: right top;
                background-repeat: no-repeat;
                background-image: url('./../img/q2.png');
                span{
                    position: relative;
                    width: 97%;
                    display: block;
                    &:after{
                        content: '';
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        border-top: 1px solid #26a39a;
                    }
                }
                p{
                    position: relative;
                    z-index: 2;
                    display: inline-block;
                    padding-right: 37px;
                    background: $white;
                    position: relative;
                    font-weight: 600;
                    color: #023671;
                }
            }
        }
        &.quote2{
            margin: 50px 0;
            padding-left: 37px;
            border-left: 3px solid #26a39a;
            color: #023671;
            > div{
                line-height: 30px;
            }
        }
        &.small_gallery{
            //padding-top: 50px;
           // border-top: 1px solid $border_color;
            div.galery_list{
                ul{
                    margin: 0 -15px;
                    li{
                        display: inline-block;
                        vertical-align: top;
                        padding: 0 15px;
                        width: 25%;
                        overflow: hidden;
                        margin-bottom: 50px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-bottom: 30px;
                        }
                        div.image{
                            width: 100%;
                            height: 0;
                            padding-bottom: 70%;
                            @include bg_def_pos;
                            background-size: contain;

                        }
                    }
                }
            }
        }
        &.download{
            padding-top: 45px;
            border-top: 1px solid $border_color;
            margin-bottom: 45px;
            .download_list{
                margin-bottom: 40.5px;
            }
            .download_info{
                display: table;
                height: 82.8px;
                width: 100%;
                font-size: 13.5px;
                line-height: 13.5px;
                font-family: $special_font;
                font-weight: 700;
                padding: 0 36px;
                border: 1px solid $border_color;
                -webkit-box-shadow: 0px 0px 21px 11px rgba(233,237,242,0.6);
                -moz-box-shadow: 0px 0px 21px 11px rgba(233,237,242,0.6);
                box-shadow: 0px 0px 21px 11px rgba(233,237,242,0.6);
                @media only screen and (max-width: 1200px) {
                    padding: 0 20px;
                }
                @media only screen and (max-width: 991px) {
                    height: auto;
                    display: block;
                    padding: 25px;
                    text-align: center;
                }
                &.with_sent{
                    @media only screen and (max-width: 1200px) {
                        font-size: 10px;
                        height: 62px;
                        .options1{
                            > ul {
                                > li{
                                    p,a{
                                        padding-left: 29px;
                                        background-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        height: auto;
                        > div{
                            width: 100%;
                            display: block;
                        }
                    }
                    > div{
                        width: auto;
                    }
                }
                p,a{
                    color: #0c2a5f;
                    font-weight: inherit;
                }
                > div{
                    display: table-cell;
                    vertical-align: middle;
                    height: 100%;
                    width: 50%;
                    &.options1{
                         > ul{
                           > li{
                               display: inline-block;
                               vertical-align: middle;
                               text-align: left;
                               margin-right: 20px;
                               @media only screen and (max-width: 991px) {
                                   margin-right: 0;
                                   width: 100%;
                                   vertical-align: top;
                                   p,a{
                                       padding-left: 0 !important;
                                       line-height: 13px;
                                   }
                            }
                               p{
                                   margin-right: 0;
                               }
                               &.sent_holder{
                                   position: relative;
                                   .sent_popup{
                                       display: none;
                                       border: 1px solid #dfe4ef;
                                       border-bottom: none;
                                       width: 100%;
                                       position: absolute;
                                       bottom: calc(100% + 26px);
                                       left: 0;
                                       padding: 15px;
                                       background: $white;
                                       @media only screen and (max-width: 1200px) {
                                           bottom: calc(100% + 16px);
                                       }
                                       @media only screen and (max-width: 991px) {
                                           border: 1px solid #dfe4ef;
                                           width: 100%;
                                           position: relative;
                                           bottom: auto;
                                           margin-top: 15px;
                                       }
                                       input{
                                           outline: none;
                                           width: 100%;
                                           border: 1px solid #dfe4ef;
                                           font-size:12px;
                                           height: 30px;
                                           padding: 0 15px;
                                           &.sent_input{
                                               margin-bottom: 10px;
                                           }
                                           &.sent_submit{
                                               text-align: center;
                                               text-transform: uppercase;
                                               font-size: 10px;
                                               font-weight: 700;
                                               color: $white;
                                               background: $accent_color2;
                                               border: $accent_color2;
                                           }
                                       }
                                   }
                               }
                               &:last-child{
                                   margin-right: 0;
                               }
                                p,a{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    @media only screen and (max-width: 1200px) {
                        width: auto;
                    }
                    @media only screen and (max-width: 600px) {
                        width: 100%;
                        display: block;
                    }
                    a,p{
                        line-height: 27px;
                        display: inline-block;
                        vertical-align: middle;
                        padding-left: 39.6px;
                        margin-right: 63px;
                        height: 27px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        @media only screen and (max-width: 1200px) {
                            margin-right: 25px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            width: 100%;
                            text-align: center;
                            padding-left: 0;
                            padding-top: 44px;
                            background-position: center top;
                            height: auto;
                            margin-top: 20px;
                        }
                        &.d_btn{
                            background-image: url('./../img/d_icon1.png');
                        }
                        &.p_btn{
                            background-image: url('./../img/print.png');
                        }
                        &.s_bnt{
                            cursor: pointer;
                            background-image: url('./../img/send_icon.png');
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        &.comments{
            padding-top: 50px;
            border-top: 1px solid $border_color;
            .section_header{
                .left_side{
                    div{
                        display: inline-block;
                        vertical-align: middle;
                        &.comments_count{
                            border: 1px solid $border_color;
                            margin-left: 16px;
                            font-size: 18px;
                            color: #0c2a5f;
                            width: 38px;
                            height: 38px;
                            line-height: 38px;
                            border-radius: 50%;
                            text-align: center;
                        }
                    }
                }
                .right_side{
                    padding-top: 7px;
                    @media only screen and (max-width: 1200px) {
                        padding-top: 0;
                    }
                    @media only screen and (max-width: 991px) {
                        padding-top: 15px;
                    }
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 18px;
                            font-size: 10.8px;
                            line-height: 31.5px;
                            font-family: $special_font;
                            color: #0c2a5f;
                            @media only screen and (max-width: 1200px) {
                                margin-right: 9px;
                                &:first-child{
                                    display: none;
                                }
                            }
                            @media only screen and (max-width: 991px) {
                                display: block;
                                margin-right: 0;
                                margin-bottom: 13.5px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                            p{
                                font-weight: 800;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            .social_btn{
                                font-family: $special_font;
                                color: $white;
                                font-weight: 800;
                                height: 31.5px;
                                line-height: 31.5px;
                                display: inline-block;
                                padding: 0 27.9px 0 51.3px;
                                background-repeat: no-repeat;
                                background-position: left 27px center;
                                border-radius: 5px;
                                @media only screen and (max-width: 1200px) {
                                    padding: 0 27.9px 0 40.5px;
                                    background-position: left 15px center;
                                }
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    background-position: left 15px center;
                                    text-align: left;
                                }
                                &.fb{
                                    background-image: url('./../img/s_btn_icon1.png');
                                    background-color: #3a5a97;
                                }
                                &.tw{
                                    background-image: url('./../img/s_btn_icon2.png');
                                    background-color: #55acef;
                                }
                                &.m{
                                    background-image: url('./../img/s_btn_icon3.png');
                                    background-color: #49cbc2;
                                    @media only screen and (max-width: 991px) {
                                        background-position: left 20px center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .post_holder{
                &.create_post{
                    margin-bottom: 10px;
                }
               > div{
                    display: inline-block;
                    vertical-align: top;
                    &.image{
                        margin-top: 3px;
                        width: 48px;
                        height: 0;
                        padding-bottom: 48px;
                        border-radius: 50%;
                        @include bg_def_pos;
                        background-color: #c4cfe3;
                        background-image: url('./../img/post_no_img.png');
                        background-size: 50% auto;
                    }
                    &.holder{
                        padding-left: 20px;
                        width: calc(100% - 48px);
                        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            font-family: $special_font;
                                color: #a5abb8;

                          }
                          ::-moz-placeholder { /* Firefox 19+ */
                            font-family: $special_font;
                            color: #a5abb8;
                          }
                          :-ms-input-placeholder { /* IE 10+ */
                            font-family: $special_font;
                            color: #a5abb8;
                          }
                          :-moz-placeholder { /* Firefox 18- */
                            font-family: $special_font;
                            color: #a5abb8;
                          }
                        .post_input{
                            border-radius: 7px;
                            outline: none;
                            border: 1px solid $border_color;
                            width: 100%;
                            height: 61px;
                            padding:0 22px;
                            font-size:12.5px;
                            font-family: $special_font;
                            color: #a5abb8;
                            font-weight: 700;
                        }
                        div{
                            font-family: $special_font;
                            font-size:10px;
                            color: #bbbdc1;
                            span{
                                &:before{
                                    content: '•';
                                    margin: 0 14px;
                                }
                            }
                            &.post_top{
                                div{
                                    display: inline-block;
                                    vertical-align: middle;
                                    &.who{
                                        color: #0c2a5f;
                                        font-size:13px;
                                        p{
                                            font-weight: 800;
                                        }
                                    }
                                }
                            }
                            &.post_middle{
                                color: $font_color;
                                font-size:15px;
                                line-height: 30px;
                                padding: 5px 0;
                            }
                            &.post_bottom{
                                border-bottom: 1px solid $border_color;
                                padding-bottom: 15px;
                                margin-bottom: 16px;
                                ul{
                                    li{
                                        display: inline-block;
                                        vertical-align: top;
                                        a{
                                            color: #bbbdc1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

aside{
    li.m_btn{
        > div{
            position: relative;
            overflow: hidden;
            .show_more{
                width: 42px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background: $accent_color2;
                @include bg_def_pos;
                background-image: url('./../img/more_arr123.png');
                @include anim;
                &.o{
                    transform: rotate(90deg);
                }
            }
        }
    }
    font-size: 12px;
    line-height: 34px;
    font-family: $special_font;
    strong{
        font-weight: 800;
    }
    header{
        margin-bottom: 18px;
        color: $accent_color1;
        h3{
            font-size: 24.3px;
        }
    }
    p,a{
        color: $accent_color1;
        font-weight: 600;
    }
    a{
        @include anim;
    }
    nav{
        @media only screen and (max-width: 991px) {
            display: none;
            padding-bottom: 27px;
        }
        > ul{
            > li{
                ul{
                    display: none;
                }
            }
        }
        ul{
            list-style: disc;
            padding-left: 5px;
            @media only screen and (max-width: 991px) {
                list-style: none;
                padding-left: 0;
                ul{
                    padding-left: 15px !important;
                }
            }
            li{
                color: $accent_color3;
                ul{
                    padding-left: 0;
                    list-style: none;
                     li{
                         padding-left:27px;
                         background-position: left top 11px;
                         background-repeat: no-repeat;
                         background-image: url('./../img/menu_close.png');
                         padding-right:25px;
                        @include anim;
                        @media only screen and (max-width: 991px) {
                            padding-left: 0;
                            background-image: none;
                        }
                        &.open{
                            background-image: url('./../img/menu_open.png');
                        }
                    }
                }
                a{
                    @media only screen and (max-width: 991px) {
                        display: block;
                        border-bottom: 1px solid $border_color;
                        @include anim;
                        padding: 13.5px 47px 13.5px 13.5px;
                        font-size: 10.5px;
                        line-height: 10.5px;
                        font-family: $special_font;
                        font-weight: 800;
                    }
                }
                .on{
                    a{
                        font-weight: 800;
                        text-decoration: underline;

                    }
                }
            }
        }
    }
}

section.special{
    padding-bottom: 64px;
}
.training{
    padding-bottom: 64px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 40px;
    }
    div{
        &.training_list{
            > ul{
               > li{
                   &:hover{
                       .desc{
                           @include shadow;
                       }
                   }
                    margin-bottom: 40px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 30px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    article{
                        div.tags{
                            text-transform: uppercase;
                            > div{
                                display: inline-block;
                                vertical-align: top;
                                &.date{
                                    height: 36px;
                                    font-size: 13px;
                                    line-height: 36px;
                                    background: #1b4a7f;
                                    width: 135px;
                                    text-align: center;
                                    color: $white;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                    }
                                    p{
                                        font-weight: 700;
                                    }
                                }
                                &.tags_list{
                                    width: calc(100% - 135px);
                                    padding-left: 2px;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                        padding-left: 0;
                                    }
                                    ul{
                                        display: flex;
                                        width: 100%;
                                        @media only screen and (max-width: 991px) {
                                            display: block;
                                        }
                                        li{
                                            height: 36px;
                                            line-height: 36px;
                                            color: $white;
                                            font-size: 12.5px;
                                            background-repeat: no-repeat;
                                            background-position: left 20px center;
                                            padding: 0 17px 0 55px;
                                            margin-right: 2px;
                                            @media only screen and (max-width: 991px) {
                                                width: 100%;
                                                margin-right: 0;
                                            }
                                            &:last-child{
                                                margin-right: 0;
                                            }
                                            &.dentist{
                                                background-image: url('./../img/tag_icon1.png');
                                                background-color: #96aeca;
                                            }
                                            &.elearning{
                                                background-color: #9dc2b7;
                                                background-image: url('./../img/tag_icon2.png');
                                            }
                                            &.doctor{
                                                background-image: url('./../img/tag_icon1.png');
                                                background-color: #b799c7;
                                            }
                                            &.stationary{
                                                background-color: #c2c09d;
                                                background-image: url('./../img/tag_icon3.png');
                                            }
                                            p{
                                                font-weight: 700;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .desc{
                            @include anim;
                            margin-top: -1px;
                            padding:26px 30px;
                            border: 1px solid $border_color;
                            color: $font_color;
                            position: relative;
                            > div{
                                margin-bottom: 30px;
                            }
                            header{
                                margin-bottom: 12px;
                                h3{
                                    font-family: $special_font;
                                    color: $header_color;
                                    font-size: 21px;
                                    line-height: 31px;
                                }
                            }
                            > .certificate {
                                position: absolute;
                                right: 30px;
                            }
                            @media only screen and (max-width: 991px) {
                                .more_btn2{
                                    width: 100%;
                                }
                            }

                            .forkind {
                                padding-top: 20px;
                                font-size: 15px;
                                text-transform: uppercase;
                                color: $header_color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.register{
    background: #edf0f3;
    .register_box{
        padding: 60px 60px 60px 60px;
        @media only screen and (max-width: 1200px) {
            padding: 0 0 60px 0;
        }
        > div{
            background: $white;
            @include shadow;
            padding: 60px 60px 30px 60px;
            @media only screen and (max-width: 991px) {
                padding: 30px 15px 30px 15px;
            }
        }
    }
}

.events{
    margin-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 30px;
    }
    .events_calendar{
        margin-bottom: 70px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        .mycalendar2_slider{
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .rwd_calendar{
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
            }
        }
    }
    div{
        &.events_list{
            ul{
                margin: 0 -17px;
                li{
                    display: inline-block;
                    vertical-align: top;
                    padding: 0 17px;
                    width: 33.33%;
                    margin-bottom: 34px;
                    @media only screen and (max-width: 600px) {
                        width: 100%;
                        padding: 0;
                    }
                    article{
                        @include anim;
                        &:hover{
                            transform: scale(1.05);
                        }
                    }
                    div{
                        &.image{
                            @include bg_def_pos;
                            background-size: contain;
                            height: 180px;
                            width: 100%;
                            position: relative;
                            @media only screen and (max-width: 600px) {
                                height: 0;
                                padding-bottom: 100%;
                            }
                            .tag{
                                position: absolute;
                                top: 10px;
                                left: 10px;
                                font-size: 10px;
                                line-height: 17px;
                                height: 17px;

                                color: #023671;
                                padding: 0 10px;
                                p{
                                    font-weight: 700;
                                }
                                &.tag1{
                                    background: #e2dc74;
                                }
                                &.tag2{
                                    background: #75cba4;
                                }
                            }
                        }
                        &.desc{
                            height: 160px;
                            background: #e9ecf1;
                            padding: 25px 30px;
                            @media only screen and (max-width: 991px) {
                                padding: 25px;
                            }
                            .date{
                                font-size: 15px;
                                color: $font_color;
                                margin-bottom: 20px;
                            }
                            h3{
                                font-size:18px;
                                line-height: 24px;
                                color: #023671;
                                font-weight: 800;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }
    }
}

.page_error{
    background-repeat: no-repeat;
    background-position: left -9% center;
    background-size: 55% auto;
    height: 100vh;
    width: 100%;
    @include vertical;
    font-family: $special_font;
    font-size: 18px;
    line-height: 28px;
    @media only screen and (max-width: 1500px) {
        font-size: 15px;
        line-height: 25px;
    }
    @media only screen and (max-width: 991px) {
        height: auto;
        padding-top: 260px;
        background-position: center top 20px;
        background-size: 300px auto;
        text-align: center;
        padding-bottom: 50px;
    }
    &.error404{
        background-image: url('./../img/404.png');
    }
    &.error500{
        background-image: url('./../img/500.png');
    }
    .desc{
        padding-left: 15px;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
        }
    }
    p{
        font-weight: 600;
        margin-bottom: 7%;
    }
    h1{
        font-size: 62px;
        line-height: 77px;
        color: #0c2a5f;
        margin-bottom: 7%;
        @media only screen and (max-width: 1500px) {
            font-size: 43px;
            line-height: 57px;
            margin-bottom: 5%;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 31px;
            line-height: 41px;
        }
        strong{
            font-weight: 800;
        }
    }
}

aside{
    ul{
        li{
            position: relative;
            //padding-right: 16px;
        }
    }
    .aaa_btn{
        width: 25px;
        height: 25px;
        background: #7fc3d4;
        position: absolute;
        top: 0;
        right: 0;
        &.bbb{
            > div{
                transform: rotate(90deg);
            }
        }
        cursor: pointer;
        > div{
            @include anim;
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('./../img/more_arr123.png');
        }
    }
    padding-top: 30px;
    header{
        position: relative;
        @media only screen and (max-width: 991px) {
            .rwd_menu_btn{
                width: 30px;
                position: absolute;
                right: 0;
                top: 5px;
                > div{
                    width: 100%;
                    height: 3px;
                    background: $font_color2;
                    margin-bottom: 3px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

body{
    &.contrast{
        .chosen-container-single .chosen-single{
            background: $black !important;
            box-shadow: none !important;
            border: 1px solid yellow !important;
        }
        .checkbox_image_box{
            border:1px solid yellow !important;
            > div{
                &.yes{
                    &.on{
                        background: red !important;
                    }
                }
            }
        }
        form {
            .rules{
                ul{
                    li{
                        background: $black !important;
                    }
                }
            }
            label{
                color: yellow !important;
            }
            input.input, .default_input{
                background: $black !important;
                border:1px solid yellow !important;
                box-shadow:none !important;
            }
        }
        .top2{
            background: $black !important;
            border: none !important;
        }
        article{
            border: none !important;
        }
        .menu{
            ul{
                background: $black !important;
                li{
                    ul{
                        a{
                            border-bottom: 1px solid yellow !important;
                        }
                    }
                }
            }
        }
        ul{
            list-style: none;
            li{
                background-image: none !important;
            }
        }
        .more_btn2{
            background: $black;
        }
        li{
            border: none !important;
        }
        .more_btn3{
            background: $black;
        }
        footer{
            background: $black !important;
            border: none !important;
        }
        .mycalendar2_datepicker {
            .special_bg_color {
                p{
                    background: red;
                }
            }
            .popup_html{
                background: $black !important;
            }
        }
        div, article, ul, li{
            box-shadow: none !important;
        }
        form{
            border: none !important;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: yellow !important;
          }
          ::-moz-placeholder { /* Firefox 19+ */
            color: yellow !important;
          }
          :-ms-input-placeholder { /* IE 10+ */
            color: yellow !important;
          }
          :-moz-placeholder { /* Firefox 18- */
            color: yellow !important;
          }
        input{
            color: yellow !important;
            background-image: none !important;
        }
        header{
            background-image: none !important;
            border: none !important;
        }
        .desc_top{
            &:before{
                display: none !important;
            }
        }
        background: $black;
        .slider, .small_slider{
            display: none !important;
        }
        h1,h2,h3,h4,h5,h6,p, span, div{
            color: yellow !important;
        }
        p{
            strong{
                color: yellow !important;
            }
        }
        div{
            background: $black !important;
            border: none !important;
        }
        a{
            color: red !important;
            background: $black;
            span{
                color: red !important;
            }
            &:after{
                display: none !important;
            }
        }
        p, span{
            &:after{
                display: none !important;
            }
        }
        section{
            background: $black;
        }
        div{
            &.image{
                background: yellow !important;
                div{
                    background: yellow !important;
                }
            }
        }
    }
}

.ui-widget-header{
    background: transparent !important;
    border: none !important;
}

.ui-tabs .ui-tabs-nav{
    padding: 0 !important;
    margin-bottom: -1px !important;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    background: $accent_color2;
    border: 1px solid $accent_color2;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default{
    border: 1px solid $border_color !important;
}

.ui-widget.ui-widget-content{
    border:none !important
}

.ui-tabs .ui-tabs-panel{
    border: 1px solid $border_color !important;
    font-size: 14px;
    text-align: justify;
    //background: #f6f6f6 !important;
}

.ui-tabs .ui-tabs-nav li{
    @media only screen and (max-width: 991px) {
        float: none;
        width: 100%;
        margin: 0;
    }
}

.ui-tabs .ui-tabs-nav .ui-tabs-anchor{
    font-size: 16px;
}

.dashboard{
    background: $theme_color2;
    font-family: $special_font;
    padding-bottom: 10%;
    @media only screen and (max-width: 1200px) {
        padding-bottom: 50px;
    }
    .section_header{
        margin-bottom: 30px;
    }
    .split_view{
        margin: 0 -20px;
        > div{
            padding:0 20px;
            display: inline-block;
            vertical-align: top;
            > div{
                background: $white;

            }
            &.summary_side{
                width: 320px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                }
                .summary_box{
                    padding: 47px 30px 50px 30px;
                    border-radius:5px;
                    border-bottom: 2px solid #e6ebf2;
                    @media only screen and (max-width: 991px) {
                        padding: 30px 15px;
                    }
                }
                div{
                    &.avatar{
                        position: relative;
                        width: 110px;
                        height: 0;
                        padding-bottom: 110px;
                        margin: 0 auto 30px auto;

                        .image{
                            @include fill;
                            @include bg_def_pos;
                            background-image: url('./../img/default_avatar.png');
                            .icon{
                                width: 40px;
                                height: 0;
                                padding-bottom: 32px;
                                background:#d7dfe7;
                                @include bg_def_pos;
                                background-image: url("./../img/edit_icon.png");
                                border-radius: 50%;
                                border: 4px solid $white;
                                margin: 0 0 0 auto;
                                position: absolute;
                                right: -5px;
                                top: -5px;
                            }
                        }
                        .myform2_dropzone_box{
                            @include fill;
                            opacity: 0;
                            z-index: 2;
                            padding: 0;
                        }
                    }
                    &.name{
                        font-size: 11px;
                        color: #5f7291;
                        font-weight: 700;
                        p{
                            font-weight: inherit;
                        }
                        h3{
                            font-size: 18px;
                            font-weight: 700;
                            color: $accent_color1;
                            margin-bottom: 10px;
                        }
                    }
                    &.stats{
                        padding: 30px 0;
                        ul{
                            li{
                                color: $accent_color1;
                                font-weight: 700;
                                font-size: 12.5px;
                                line-height: 30px;
                                p{
                                    font-weight: inherit;
                                }
                            }
                        }
                    }
                }
            }
            &.dashboard_content_side{
                width: calc(100% - 320px);
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                .dashboard_content_box{
                    border-radius:5px;
                    border-bottom: 2px solid #e6ebf2;
                    div{
                        &.dashboard_toggles{
                            background: $white;
                            border-bottom: 1px solid #e8ecf1;
                            .under_menu {
                                li {
                                    font-size: 12.5px;
                                    padding: 0 15px;
                                    height: 55px;
                                    line-height: 55px;
                                }
                            }
                            ul{
                                li{
                                    display: inline-block;
                                    vertical-align: top;
                                    height: 75px;
                                    line-height: 75px;
                                    padding: 0 30px;
                                    color: $accent_color1;
                                    font-size: 20px;
                                    font-weight:600;
                                    cursor: pointer;
                                    position: relative;
                                    border-left: 1px solid $white;
                                    border-right: 1px solid $white;
                                    @media only screen and (max-width: 1200px) {
                                        font-size: 13px;
                                        height: 55px;
                                        line-height: 55px;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                        text-align:center;
                                    }
                                    &:before{
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 50%;
                                        width: 0;
                                        border-top: 3px solid #4fccc3;
                                        @include anim;
                                    }
                                    &.active{
                                        border-left: 1px solid #e8ecf1;
                                        border-right: 1px solid #e8ecf1;
                                        font-weight: 700;
                                        &:before{
                                            left: 0;
                                            width: 100%;
                                        }
                                        &:after{
                                            content: '';
                                            position: absolute;
                                            top: 100%;
                                            left: 0%;
                                            width: 100%;
                                            border-top: 3px solid $white;
                                        }
                                        p{
                                            font-weight: inherit;
                                        }
                                    }
                                }
                            }
                        }
                        &.dashboard_content{
                            padding: 30px 40px 40px 40px;
                            @media only screen and (max-width: 991px) {
                                padding: 30px 15px;
                            }
                            > div{
                                display: none;
                                &.active{
                                    display: block;
                                }
                            }
                            .your_posts, .your_coments{
                               > ul{
                                   > li{
                                        //min-height: 170px;
                                        padding: 18px;
                                       color: #5f7291;
                                       border: 1px solid #dae1e8;
                                       border-radius: 5px;
                                       position: relative;
                                       margin-bottom: 20px;
                                       &:last-child{
                                           margin-bottom: 0;
                                       }
                                        .go_to_btn{
                                            position: absolute;
                                            top: 20px;
                                            right: 20px;
                                            @media only screen and (max-width: 991px) {
                                                position: relative;
                                                top: 0;
                                                left: 0;
                                                text-align: center;
                                                display: block;
                                                margin: 0 auto;
                                                padding-top: 15px;
                                            }
                                        }
                                       .post_info{
                                           > div{
                                               display: inline-block;
                                               vertical-align: middle;
                                               &.date{
                                                   width: 240px;
                                                   font-size: 12.5px;
                                                   padding-left: 27px;
                                                   background-position: left center;
                                                   background-repeat:no-repeat;
                                                   background-image: url("./../img/clock_icon.png");
                                                   font-weight:600;
                                                   @media only screen and (max-width: 991px) {
                                                        width: 100%;
                                                   }
                                                   p{
                                                       font-weight: inherit;
                                                   }
                                               }
                                               &.post_way{
                                                   width: calc(100% - 240px);
                                                   padding-left: 20px;
                                                   padding-right: 35px;
                                                   @media only screen and (max-width: 991px) {
                                                        width: 100%;
                                                       padding-left: 0;
                                                       padding-top: 15px;
                                                       padding-right: 0;
                                                   }
                                                   ul{
                                                       li{
                                                           display: inline-block;
                                                           vertical-align: top;
                                                           font-size: 10.5px;
                                                           &:first-child{
                                                               padding-left: 27px;
                                                               background-repeat: no-repeat;
                                                               background-position: left center;
                                                               background-image: url("./../img/homee_icon.png");
                                                           }
                                                           &:last-child{
                                                               a{
                                                                   text-decoration: underline;
                                                               }
                                                               &:after{
                                                                   display: none;
                                                               }
                                                           }
                                                           &:after{
                                                               content: '- ';
                                                               margin: 0 10px;
                                                           }
                                                           a{
                                                                font-weight: 700;
                                                               color: #5f7291;
                                                           }
                                                       }
                                                   }
                                               }
                                           }
                                       }
                                       .desc{
                                           padding-top: 25px;
                                           font-size: 20px;
                                           line-height: 30px;
                                           font-family: $main_font;
                                       }
                                    }
                                }
                            }
                            .your_topics{
                                ul{
                                    li{
                                        margin-bottom: 10px;
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                        &.new{
                                            .title{
                                                background-image: url("./../img/forum_new_icon.png");
                                            }
                                        }
                                        &.old{
                                            .title{
                                                background-image: url("./../img/forum_no_new_icon.png");
                                            }
                                        }
                                        &.new_blocked{
                                            .title{
                                                background-image: url("./../img/forum_new_blocked_icon.png");
                                            }
                                        }
                                        &.old_blocked{
                                            .title{
                                                background-image: url("./../img/forum_no_new_icon.png");
                                            }
                                        }
                                       .topic_holder{
                                           border: 1px solid #dae1e8;
                                           display: flex;
                                           min-height: 75px;
                                           width: 100%;
                                           padding-top: 18px;
                                           padding-left: 25px;
                                           border-radius: 10px;
                                           @media only screen and (max-width: 991px) {
                                                display: block;
                                               padding: 18px;
                                           }
                                           > div{
                                               @media only screen and (max-width: 991px) {
                                                    display: inline-block;
                                                   vertical-align: top;
                                               }
                                           }
                                           .title{
                                               width: calc(100% - (135px + 135px));
                                               padding-left: 55px;
                                               background-repeat: no-repeat;
                                               background-position: left top 8px;
                                               @media only screen and (max-width: 991px) {
                                                    width: 100%;
                                                   margin-bottom: 15px;
                                                   padding-top: 55px;
                                                   padding-left: 0;
                                                   background-position: center top;
                                                   text-align: center;
                                               }
                                               .title_name{
                                                   h3{
                                                       color: $accent_color1;
                                                       font-size:18px;
                                                       font-weight: 700;

                                                       @media only screen and (min-width: 991px) {
                                                           display: -webkit-box;
                                                           -webkit-line-clamp: 1;
                                                           -webkit-box-orient: vertical;
                                                           overflow: hidden;
                                                           text-overflow: ellipsis;
                                                       }
                                                       .status{
                                                           font-weight: 400;
                                                       }
                                                   }
                                               }
                                               .who{
                                                   padding-top: 5px;
                                                   color: #5f7291;
                                                   font-weight: 600;
                                                   p{
                                                       font-weight: inherit;
                                                   }
                                                   span{
                                                       font-weight: 700;
                                                       &.admin{
                                                           color: #da2234;
                                                       }
                                                   }
                                               }
                                           }
                                           .answers, .views{
                                               width: 135px;
                                               text-align: center;
                                               color:#5f7291;
                                               font-weight: 600;
                                               @media only screen and (max-width: 991px) {
                                                    width: 50%;
                                               }
                                               p{
                                                   font-weight: inherit;
                                               }
                                               span{
                                                   font-size: 18px;
                                                   font-weight: 700;
                                                   color: $accent_color1;
                                                   margin-bottom: 5px;
                                                   display: block;
                                               }
                                           }
                                       }
                                    }
                                }
                            }
                            .your_data{
                                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                    color: #5f7291;
                                }
                                ::-moz-placeholder { /* Firefox 19+ */
                                    color: #5f7291;
                                }
                                :-ms-input-placeholder { /* IE 10+ */
                                    color: #5f7291;
                                }
                                :-moz-placeholder { /* Firefox 18- */
                                    color: #5f7291;
                                }
                                input{
                                    outline: none;
                                    &.data_input{
                                        width: 100%;
                                        height: 47px;
                                        border: 1px solid #dfe4eb;
                                        border-bottom: 2px solid #dfe4eb;
                                        border-radius: 5px;
                                        padding: 0 20px;
                                        color: #5f7291;
                                        font-weight: 600;
                                        font-family: $special_font;
                                    }
                                    &.edit_submit{
                                        width:225px;
                                        height: 52px;
                                        background: $accent_color1;
                                        color: $white;
                                        font-weight:800;
                                        font-family: $special_font;
                                        text-transform: uppercase;
                                        border:none;
                                        @media only screen and (max-width: 991px) {
                                            width: 100%;
                                        }
                                    }
                                }
                                .default_data{
                                    .holder{
                                        margin-bottom: 20px;
                                        &.text-right{
                                          div{
                                              &:first-child{
                                                  padding-right: 0;
                                              }
                                              &:last-child{
                                                  padding-top: 40px;
                                                  width: 100%;
                                              }
                                          }
                                        }
                                        > div{
                                            display: inline-block;
                                            vertical-align: middle;
                                            &:first-child{
                                                width: 220px;
                                                text-align: right;
                                                padding-right: 44px;
                                                @media only screen and (max-width: 991px) {
                                                    text-align: center;
                                                    width: 100%;
                                                    margin-bottom: 10px;
                                                    padding-right: 0;
                                                }
                                            }
                                            &:last-child{
                                                width: calc(100% - 220px);
                                                @media only screen and (max-width: 991px) {
                                                    width: 100%;
                                                }
                                            }
                                            label{
                                                color: $accent_color1;
                                                font-weight: 700;
                                                font-size: 15px;
                                            }

                                        }
                                        .chosen-container-single {
                                            .chosen-single{
                                                height: 47px;
                                                box-shadow:none;
                                                line-height: 46px;
                                                border: 1px solid #dfe4eb;
                                                border-bottom: 2px solid #dfe4eb;
                                            }
                                        }
                                    }
                                }
                                .password_change{
                                    div.holder{
                                        margin-bottom: 20px;
                                        &:last-child{
                                            margin-top: 40px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.list{
    &.image_list{
        ul{
            li{
                //margin-bottom: 50px;
                margin-bottom: 27px;
                @include anim;
                &:hover{
                    transform: scale(1.05);
                }
                article{
                    padding: 0;
                    border: none;
                    display: table;
                    width: 100%;
                    //min-height: 240px;
                    min-height: 108px;
                    border:1px solid #eeeeee;
                    padding: 13.5px;
                    @media only screen and (max-width: 991px) {
                        display: block;
                    }gulp gulpwatch
                    &:hover{
                        box-shadow: none;
                        .desc{
                            header{
                                h3{
                                    color: $accent_color2;
                                }
                            }
                        }
                    }
                    > div{
                        display: table-cell;
                        vertical-align: top;
                        height: 100%;
                        @media only screen and (max-width: 991px) {
                            display: block;
                        }
                        &.image{
                            //width: 240px;
                            width: 108px;
                            @include bg_def_pos;
                            background-size: cover;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                height: 0;
                                padding-bottom: 100%;
                                margin-bottom: 10px;
                            }
                        }
                        &.desc{
                            //width: calc(100% - 240px);
                            width: calc(100% - 108px);
                            padding-left: 36px;
                            position: relative;
                            line-height: 23.4px;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                padding-left: 0;
                            }
                            header{
                                margin-bottom: 9px;
                                h3{
                                    @include anim;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                            p{
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .more_btn2{
                                position: absolute;
                                bottom: 0;
                                left: 36px;
                                display: none;
                                @media only screen and (max-width: 991px) {
                                    left: 0;
                                    position: relative;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ul{
        li{
            margin-bottom: 30px;
            /*&:last-child{
                margin-bottom: 0;
            }*/
            article{
                padding:20px 27px;
                border: 1px solid $border_color;
                color: $font_color;
                @include anim;
                &:hover{
                    @include shadow;
                }
                .date_header{
                    margin-bottom: 23px;
                }
                .desc{
                    margin-bottom: 20px;
                    //line-height: 22px;
                }
            }
        }
    }
}

.zwinrozwin{
    cursor: pointer;
    margin-bottom:25px;
    >div{
        height: 88px;
        overflow: hidden;
    }
    &.open{
        >div{
            height: auto;
        }
    }

    >p{
            display: inline-block;
            margin-top: 15px;
            background: #49cbc2c2;
            height: 30px;
            line-height: 30px;
            padding: 0 15px;
            color: $white;
    }
}

.activefrontday{
    line-height: 16px !important;
    border:1px solid #023671;
}
.universal{
    .download{
        .download_info{
            > div{
                @media only screen and (max-width: 991px) {
                    width: 100% !important;
                }
            }
        }
    }
    .begin{
        .more_btn{
            background: $accent_color2;
            text-transform: uppercase;
            padding: 0;
            width: 200px;
            border: none;
            font-size: 12px;
            margin-top: 20px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 50px;
            }
            &:hover{
                color: $white;
            }
        }
    }
    .question{
        margin-bottom: 30px;
        > div{
            display: inline-block;
            vertical-align: middle;
            &.number{
                width: 22px;
                height: 22px;
                line-height: 22px;
                font-size: 12px;
                font-weight: 700;
                color: $white;
                background: $accent_color2;
                text-align: center;
                border-radius: 50%;
            }
            &.desc{
                padding-left: 10px;
                width: calc(100% - 22px);
            }
        }
    }
    .test_box{

        .field_checkbox_box{
            margin-bottom: 15px;
            > div{
                display: inline-block;
                vertical-align: top;
                .checkbox_image_box{
                    width: 20px;
                    position: relative;
                    > div{
                       width: 100%;
                       height: 0;
                       padding-bottom: 100%;
                       background-color: #eeeeee;
                       @include bg_def_pos;
                       background-size: 70% auto;
                       border: none;
                       border-radius: 0;
                       &.yes{
                           &.on{
                               background-color: #eeeeee;
                               background-image: url('./../img/check_icon123.png');
                           }
                       }
                    }
                }
                &.field_checkbox_subtitle{
                    width: calc(100% - 20px);
                    padding-left: 15px;
                    font-size: 12px;
                    padding-top: 2px;
                    font-weight: 700;
                    color: #023671;
                    p{
                        font-weight: inherit;
                    }
                }
            }
        }
    }
}

.special{
    .default_font {
        &.large{
            font-size: 13.5px;
        }
    }
}

.link0 {
    border: solid 1px #e0e2e7;
    font-size: 15px;
    color: #535353;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 10px;
    display: block;
}
.link0:hover { background-color: #fcfcfc; }
.link0 a:hover {color:#0782c1;}

.site_map{
    padding-bottom: 60px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    a{
        @include anim;
        &:hover{
            text-decoration: none;
            color: $accent_color1;
        }
    }
    > ul{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
        grid-gap: 25px;
        grid-auto-flow: dense;
        padding: 10px;
        @media only screen and (max-width: 991px) {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
        > li{
            -webkit-box-shadow: 0px 0px 16px 0px rgba(48, 41, 31, 0.2);
            -moz-box-shadow: 0px 0px 16px 0px rgba(48, 41, 31, 0.2);
            box-shadow: 0px 0px 16px 0px rgba(48, 41, 31, 0.2);
            padding: 30px;
            background: $white;
            grid-column-end: span 1;
            grid-row-end: span 1;
            > div{
                a{
                    color: $accent_color2;
                    font-size: 22.5px;
                    line-height: 31.5px;
                    font-weight: 700;
                }
            }
            > ul{
                padding-top: 20px;
                > li{
                    ul{
                        padding-left: 18px;
                        list-style:disc;
                    }
                    div{
                        a{
                            font-size: 12px;
                            line-height: 28px;
                            color: $font_color;
                            font-weight: 700;
                            @include anim;
                            &:hover{
                                padding-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.filters_news{
    margin-bottom: 30px;
}


.questionnaire{
    &.ankieta{
        width: 50%;
        margin: 30px auto;
        border:1px solid #7fc3d4;
        padding: 30px;
        @media only screen and (max-width: 1200px) {
            width: 100%;
        }
        @media only screen and (max-width: 991px) {
            padding: 15px;
            .topheader{
                text-align: center;
            }
        }
        .question {
            > div{
                vertical-align: top;
            }
        }
        .test_box{
            .desc{
                h3{
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 0;
                }
            }
        }
        .more_btn{
            width: 100% !important;
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
            }
        }
    }
}

.radio_list{
    ul{
        list-style: none;
        padding-left: 0;
        li{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
            div{
                display: inline-block;
                vertical-align: top;
                &.radio_holder{
                    width: 20px;
                    .radio_box{
                        cursor: pointer;
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        background-color: #eeeeee;
                        @include bg_def_pos;
                        background-size: 70% auto;
                        &.radio_active{
                            background-image: url("./../img/check_icon123.png");
                        }
                    }
                }
                &.radio_desc{
                    width: calc(100% - 20px);
                    padding-left: 15px;
                    color: #023671;
                    p{
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.small_banner{
    padding: 15px 0;
    background:$white;
    img{
        max-width: 100%;
        height: auto;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
}

.phone_data_input {
    width: 100%;
    height: 47px;
    border: 1px solid #dfe4eb;
    border-bottom: 2px solid #dfe4eb;
    border-radius: 5px;
    padding: 0 20px;
    color: #5f7291;
    font-weight: 600;
    font-family: "Muli", sans-serif;
}

p.fill_phone{
    color: #023671;
    font-weight: 800;
    padding-bottom:5px;
}

.page_event_counter{
    padding: 10px 0;
    background: $accent_color1;
}

.page_counter_content{
    > div{
        display: inline-block;
        vertical-align: middle;
        &.event_counter_holder{
            width: 280px;
            color: $white;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
            ul{
                text-align: center;
                padding: 25px 15px;
                &.end_time{
                    background: #c51d1d;
                }
                > li{
                    display: inline-block;
                    vertical-align: top;
                    font-size: 11.35px;
                    line-height: 18.5px;
                    span, &.seperator{
                        font-size: 30px !important;
                        font-weight: 700;
                    }
                    p{
                        padding-top: 10px;
                        font-weight: 700;
                    }
                }
            }
        }
        &.even_counter_desc{
            width: calc(100% - 280px);
            padding-left: 40px;
            @media only screen and (max-width: 767px) {
                padding-top: 20px;
                padding-left: 0;
                width: 100%;
            }
            > div{
                display: inline-block;
                vertical-align: middle;
                &.default_font{
                    width: calc(100% - 100px);
                    color: $white;
                    border-right: 1px solid rgba(255,255,255,0.3);
                    padding-right: 10%;
                    @media only screen and (max-width: 767px) {
                        text-align: center;
                        padding-right: 0;
                        width: 100%;
                        padding-bottom: 20px;
                        background-blend-mode: 20px;
                        border-right: 0;
                        border-bottom: 1px solid rgba(255,255,255,0.3);
                    }
                    p{
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                &:last-child{
                    width: 100px;
                    text-align: right;
                    @media only screen and (max-width: 767px) {
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
    }

    .text_actual {
        display:block;
        > div {
            display:none;
        }
    }

    .text_expired {
        display:none;
        > div {
            display:none;
        }
    }

    &.end_time {
        .text_actual {
            display:none!important;
        }

        .text_expired {
            display:block!important;
        }
    }
}

.all_trainings{
    .rwd_calendar{
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    .mycalendar2_datepicker {
        .header_font_color{
            background-color: #e8ecf1;
        }
        .calendar_content{
            padding: 0;
            .days_table {
                .thead {
                    .td {
                        &.active_selected{
                            background: transparent;
                        }
                        p{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .mycalendar2_datepicker .calendar_content .days_table .td.active_selected{
        background-color: transparent;
        p{
            background: $accent_color1;
        }
    }
    .mycalendar2_datepicker .calendar_content .days_table .td.active.active:hover{
        background-color: transparent !important;
        p{
            background: $accent_color1;
        }
    }
}

.select2-results{
    padding: 0 !important;
}

.select2-results__option{

    background: #f6fafb;

    &.select2-results__option--highlighted{
        background-color: $accent_color2 !important;
    }
}

.select2-dropdown{
    border: 1px solid #e0e5e8 !important;
    border-top: none;

}

.select2-container--default .select2-results__option[aria-selected=true]{
    background-color: $accent_color1 !important;
    color: $white !important;
}

.select2-results{
    padding: 0 4px;
}

.dashboard{
    .poll_content{
        @media only screen and (max-width: 767px) {
            .form_actions{
                text-align: center;
                .text-right{
                    text-align: center;
                }
                > div{
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .text-right{
            text-align: right;
        }
        &.dash_steps{
            @media only screen and (max-width: 991px) {
                margin-bottom: 50px;
            }
        }
        .select2-container--default {
            text-align: left;
            .select2-selection--single {
                height: 49.5px;
                border-radius: 0;
                outline: none;
                background: #f6fafb;
                border: 1px solid #cfdee2;
                border-radius: 5px;
                font-size: 12.6px;
                font-weight: 700;
                .select2-selection__rendered{
                    line-height: 49.5px;
                    padding: 0 24.3px;
                    padding-right: 90px;
                }
                .select2-selection__arrow{
                    width: 80px;
                    height: 100%;
                    top: 0;
                    right: 0;
                    background-repeat: no-repeat;
                    background-image: url("./../img/select_arr.png");
                    background-position: right 30px center;
                    b{
                        display: none;
                    }
                }
            }
            .select2-search--dropdown {
                .select2-search__field{
                    background: $accent_color2;
                    color: $font_color2;
                    border: none;
                    border-bottom: 2px solid #e0e5e8;
                    outline: none;
                    height: 36px;
                }
            }
        }




        padding: 30px;
        border-radius: 10px;
        border: 1px solid $border_color;
        background: $white;

        .default_form{
            margin-bottom: 30px;
        }

        .dash_form_holder{
            padding-top: 30px;
            .field-box{
                text-align: left;
            }
            label{
                text-align: left;
                margin-bottom: 11px;
            }

        }
        input[type="text"]{
            height: 49.5px;
            padding: 0 24.3px;
            width: 100%;
            background: #f6fafb;
            border: 1px solid #cfdee2;
            border-radius: 5px;
            font-weight: 600;
            color: #5e6b80;
            font-family: $special_font;
            font-size: 12.6px;
            @media only screen and (max-width: 991px) {
                font-size: 10px;
            }
        }
        textarea{
            background: #f6fafb;
            border: 1px solid #cfdee2;
            border-radius: 5px;
            font-weight: 600;
            color: #5e6b80;
            font-family: $special_font;
            font-size: 12.6px;
            padding:18px 24.3px;
            min-height: 124.2px;
            outline: none;
            resize: none;
            width: 100%;
            @media only screen and (max-width: 991px) {
                margin-bottom: 15px;
            }
        }
        .rules{
            ul{
                li{
                    padding: 28.8px 25.2px;
                    background: #f9f9f1;
                    border-radius: 5px;
                    margin-bottom: 18px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    > div{
                        display: inline-block;
                        vertical-align: top;
                        &.check_box_holder{
                            width: 18px;
                            .checkbox_image_box{
                                margin-top: 2px;
                                width: 100%;
                                height: 18px;
                                position: relative;
                                > div{
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    @include fill;
                                    background-color: $white;
                                    border: 1px solid #cdcdab;
                                    &.yes{
                                        &.on{

                                            background-image: url('./../img/rule_check.png');;
                                        }
                                    }
                                }
                            }
                        }
                        &.desc{
                            width: calc(100% - 18px);
                            padding-left: 18px;
                            color: #666661;
                            font-size:12.6px;
                            line-height: 25.2px;
                            font-family: $special_font;
                            font-weight: 600;
                            p{
                                font-weight: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
    &.poll{
        padding: 50px 0;
        @media only screen and (max-width: 767px) {
            padding: 0 0 50px 0;
        }
        .field_radio_box{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
            > div{
                display: inline-block;
                vertical-align: top;
                &.field_radio_input{
                    width:18px;

                    .radio_image_box {
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;

                        .radio_box {
                            position:relative;
                            width: 18px;
                            height: 18px;
                            background-color: #f6fafb;
                            border: 1px solid #cfdee2;
                            border-radius: 50%;
                            cursor: pointer;

                            &.on {
                                &:after {
                                    content:"";
                                    background-color: #13BAEA;
                                    width:12px;
                                    height:12px;
                                    border-radius: 50%;
                                    position:absolute;
                                    top:2px;
                                    left:2px;
                                }
                            }
                        }
                    }
                }
                &.field_radio_subtitle{
                    padding-left: 10px;
                    width: calc(100% - 18px);
                }
            }
        }


        .field_checkbox_box{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        .field_checkbox_box{
            > div{
                display: inline-block;
                vertical-align: top;
                &.field_checkbox_subtitle{
                    padding-left: 10px;
                    width: calc(100% - 18px);
                }
            }
        }
        .field_checkbox .field_checkbox_box .checkbox_image_box > div{
            width: 18px;
            height: 18px;
            background-color: #f6fafb;
            border: 1px solid #cfdee2;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 4px;
            &.yes{
                &.on{
                    background-image: url("./../img/check_icon.png");
                }
            }
        }
    }
    .dash_steps{
        > ul{
            > li{
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .dash_step_box{
        text-align: left;
        cursor: pointer;
        &.active{
            > div{
                &.dash_step{
                    background-color: $accent_color1;
                }
                &.dash_step_title{
                    color: $font_color2;
                    p{
                        color: $font_color2;
                    }
                }
            }
        }
        > div{
            display: inline-block;
            vertical-align: middle;
            &.dash_step{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: $accent_color2;
                color: $white;
                font-size: 16px;
                line-height: 40px;
                text-align: center;
                font-weight: 700;
            }
            &.dash_step_title{
                padding-left: 15px;
                width: calc(100% - 40px);
                color: #5e6b80;
                p{
                    color: #5e6b80;
                }
                font-size: 14px;
                line-height: 24px;
                @media only screen and (max-width: 1500px) {
                    font-size: 12px;
                    padding-left: 10px;
                }
            }
        }
    }
}

.row{
    &.inline_row{
        > div{
            float: none;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.poll_desc{
    margin-bottom: 30px;
    .dashboard_section_header{
        margin-bottom: 15px;
    }
}

.save_form_btn_holder{
    padding-left: 28.8px;
    padding-right: 36px;
    height: 45px;
    background-color: $accent_color1;
    display: inline-block;
    cursor: pointer;
    &:hover{
        input{
            color: $accent_color2;
            &:after{
                right: -10px;
            }
        }
    }
    input{
        height: auto;
        border: 0;
        background-color: transparent;
        display: block;
        font-size: 9.9px;
        line-height: 45px;
        color: $white;
        text-transform: uppercase;
        font-weight: 800;
        font-family: $special_font;
        position: relative;
        padding-right: 36px;
        @include anim;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url('./../img/more_arr1.png');
    }
}

.pol_end{
    height: 300px;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;

    font-size: 30px;
    line-height: 40px;
    color: $accent_color1;
}

.err_txt{
    display: inline-block;
    background-color: #d81a29;
    padding: 15px;
    color: #ffffff;
    font-weight: 600;
    border-radius: 5px;
    position: relative;
    margin-top: 20px;
    position: relative;
    min-width: 50px;
    &:before{
        content: '';
        position: absolute;
        bottom: calc(100% - 1px);
        left: 15px;
        width: 20px;
        height: 10px;
        background-color: #d81a29;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
}

.download{
    header{
        display: inline-block;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        h4{
            display: inline-block;
        }
        .default_font{
            cursor: pointer;
            display: inline-block;
            margin-right: 6px;
        }
        .wrapper{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            .circle{
                padding: 3px;
                color: white;
                width: auto;
                min-width: 24px;
                height: auto;
                min-height: 24px;
                text-align: center;
                border: 1px solid rgba(80, 191, 206, 1);
                border-radius: 50%;
                background-color: rgba(80, 191, 206, 1);
            }
            .btn_arrow{
                margin-left: 9px;
                &.arrow_down:after{
                    content: '';
                    display: block;
                    transform-origin: center;
                    transform: translateY(-25%) rotate(-45deg);
                    width: 10px;
                    height: 10px;
                    border-bottom: 2px solid rgb(80, 191, 206);
                    border-left: 2px solid rgb(80, 191, 206);
                }
                &.arrow_up:after{
                    content: '';
                    display: block;
                    transform-origin: center;
                    transform: translateY(25%) rotate(-45deg);
                    width: 10px;
                    height: 10px;
                    border-top: 2px solid rgb(80, 191, 206);
                    border-right: 2px solid rgb(80, 191, 206);
                }
            }
        }
        .title{
            display: inline-block;
        }
    }
}

.popup_password
{
    cursor: pointer;
}

.panel_popup_holder {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 991px) {
        overflow-y: scroll;
    }

    .panel_popup
    {
        display: none;
    }

    fieldset {
        margin: 0 auto;
    }
    .panel_popup_title{
        margin-bottom: 15px;
        h3{
            font-size: 22px;
            line-height: 32px;
            //padding-left: 35px;
            background-repeat: no-repeat;
            background-position: left top 5px;
            @media only screen and (max-width: 767px) {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    .panel_popup_content{
        margin: 0 auto;
        width: 690px;
        background-color: white;
        //border-radius: 10px;
        .holder{
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            &:last-child{
                margin-top: 40px;
            }
            input{
                outline: none;
                width: 100%;
                height: 47px;
                border: 1px solid #dfe4eb;
                border-bottom: 2px solid #dfe4eb;
                border-radius: 5px;
                padding: 0 20px;
                color: #5f7291;
                font-weight: 600;
                font-family: $special_font;
            }
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            border-radius: 0;
        }
        > div{
            padding: 20px 30px;
            @media only screen and (max-width: 767px) {
                padding: 10px 15px;
            }
            &.panel_popup_top{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                @media only screen and (max-width: 767px) {
                    padding-top: 32px;
                    padding-bottom: 20px;
                }
                .panel_popup_close{
                    position: absolute;
                    top: 20px;
                    right: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    @include anim;
                    @media only screen and (max-width: 767px) {
                        right: 15px;
                    }
                    &:hover{
                        background-color: white;
                    }
                }
                position: relative;
                > div{
                    //padding-right: 60px;
                    text-align: center;
                }
            }
            &:last-child{
                border-bottom: 0;
                &.panel_popup_bottom{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-top: 1px solid $border-color;
                    button, a{
                        //border-radius: 5px;
                        color: white;
                        font-weight: 800;
                        font-family: "Muli", sans-serif;
                    }
                    .close_btn1 {
                        color: $accent_color1;
                        padding-left: 36px;
                        padding-right: 36px;
                        border: none;
                    }
                    .inline_row{
                        margin: 0;
                        > div{
                            padding: 0;
                            width: auto !important;
                            &:first-child{
                                margin-right: auto;
                            }
                            &:last-child{
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
.toggle_filters{
    margin-bottom: 32px;
    .row{
        @media only screen and (max-width: 767px) {
            > div{
                margin-bottom: 16px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .select2-container--default {
        text-align: left;
        .select2-selection--single {
            background-color: #F4F9FA;
            border: 1px solid #C8D9DD;
            height: 48px;
            color: #576477;
            font-size: 14px;
            font-weight: 600;
            .select2-selection__rendered{
                line-height: 48px;
                padding-right: 52px;
                padding-left: 20px;
            }
            .select2-selection__arrow{
                width: 52px;
                height: 100%;
                top: 0;
                right: 0;
                @include bg_def_pos;
                background-image: url("./../img/toggle_arr.svg");
                b{
                    display: none;
                }
            }
        }
    }
    label{
        display: block;
        color: #576477;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 6px;
    }
    input[type="date"]{
        text-transform: uppercase;
        position: relative;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
        &::-webkit-calendar-picker-indicator {
            position: absolute;
            left: 20px;
            top: 14px;
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url('./../img/calendar_icon.svg') no-repeat;
            width: 20px;
            height: 20px;
            border-width: thin;
            @media only screen and (max-width: 767px) {
                left: 11px;
            }
        }
    }
    input[type="text"],input[type="date"]{
        background-position: left 20px center;
        background-repeat: no-repeat;
        padding-right: 20px;
        padding-left: 48px;
        background-color: #F4F9FA;
        border: 1px solid #C8D9DD;
        height: 48px;
        color: #576477;
        font-size: 14px;
        font-weight: 600;
        @media only screen and (max-width: 767px) {
            background-position: left 11px center;
            padding-left: 40px;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #576477;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #576477;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #576477;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #576477;
        }
    }
}
.filter_search_holder{
    display: flex;
    height: 48px;
    flex-flow: row;
    flex-wrap: nowrap;
    margin-bottom: 16px;
    > div{
        &:first-child{
            width: 100%;
        }
        &:last-child{
            width: 127px;
        }
    }
    input{
        height: 100%;
        align-items: stretch;
        background-repeat: no-repeat;
        &[type="text"]{
            width: 100%;
            background-image: url("./../img/toggle_search_icon.svg");
        }
        &[type="submit"]{
            border: 1px solid #003064;
            background-color: #003064;
            padding-left: 20px;
            padding-right: 57px;
            font-size: 12px;
            text-transform: uppercase;
            color: $white;
            font-weight: 700;
            background-position: right 20px center;
            background-image: url("./../img/more_arr1.png");
        }
    }
}
.toggle_filters_content{
    padding-top: 20px;
    display: none;
    @media only screen and (max-width: 767px) {
        padding-top: 16px;
    }
    .row{
        margin-bottom: 20px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 16px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.fillters_toggle_btn{
    padding-right: 58px;
    padding-left: 20px;
    border: 1px solid #C8D9DD;
    font-size: 12px;
    font-weight: 700;
    line-height: 48px;
    display: inline-block;
    color: #576477;
    position: relative;
    text-transform: uppercase;
    background-color: transparent;
    @media only screen and (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
    &.open{
        &:after{
            transform: rotate(180deg);
        }
    }
    &:after{
        content: '';
        position: absolute;
        height: 100%;
        width: 52px;
        @include bg_def_pos;
        background-image: url("./../img/toggle_arr.svg");
    }
}

.dates{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @include vertical;
    gap: 8px;
    @media only screen and (max-width: 767px) {
        flex-flow: column;
        gap: 16px;
        > div{
            width: 100%;
        }
    }
}

.seperator_date{
    padding-top: 20px;
    @media only screen and (max-width: 767px) {
        display: none;
    }
}

.loader_wrapper.button_loader_animation {
    display: flex !important;
    .loader {
        border: 5px solid #26a39a;
        border-top: 5px solid transparent;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: auto;
        animation: spin 1.5s linear infinite;
    }
}
@keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
.filter_search_holder {
    .submit_btn_filter_wrapper {
        position: relative;
    }
    .loader_wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        display: none;
    }
}

.select2-search {
    display: block !important;
}

.comment_box{
    label{
        display: block;
        color: #576477;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 6px;
    }
    textarea{
        background: #f6fafb;
        border: 1px solid #cfdee2;
        border-radius: 5px;
        font-weight: 600;
        color: #5e6b80;
        font-family: $special_font;
        font-size: 12.6px;
        padding:18px 24.3px;
        min-height: 124.2px;
        outline: none;
        resize: none;
        width: 100%;
        @media only screen and (max-width: 991px) {
            margin-bottom: 15px;
        }
    }
}
.rate_comment{
    display: flex;
    gap: 10px;
    padding-top: 15px;
}
.rate_box{
    width: 30px;
    height: 30px;
    @include bg_def_pos;
    background-image: url("./../img/star0.svg");
    position: relative;
    input{
        @include fill;
        cursor: pointer;
        opacity: 0;
    }
    &.on{
        background-image: url("./../img/star1.svg");
    }
    .error_text {
        position: absolute;
        width: max-content;
        bottom: -12px;
    }
}

.rate_submit{
    padding: 0 30px;
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    background-color: $accent_color1;
    border: 0;
    @include anim;
    @media only screen and (max-width: 991px) {
        width: 100%;
        margin-top: 20px;
    }
    &:hover{
        background-color: $accent_color2;
    }
}

.my_comment_box{
    background-color: #f6fafb;
    color: #5e6b80;
    padding: 20px;
    border-radius: 10px;
    height: 100%;
    .rate_box{
        width: 15px;
        height: 15px;
    }
    .name{
        color: $header_color;
        padding-bottom: 15px;
        font-weight: 600;
        p{
            font-weight: inherit;
        }
    }
    .default_font{
        height: calc(100% - 41px);
    }
    .rate_comment{
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid #cfdee2;
    }
    .rate_box{
        cursor: default;
    }
}

.my_comments{
    padding-top: 50px;
    > ul{
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        > li{
            width: calc(50% - 15px);
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
}

.comments_carousel{
    overflow: hidden;
    margin-bottom: 40px;
    .comments_carousel_nav{
        text-align: center;
        padding-top: 20px;
        button{
            border: 0;
            background-color: transparent;
            height: 40px;
            width: 40px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("./../img/more_arr1.png");
            &.prev{
                -moz-transform: scale(-1, -1);
                -o-transform: scale(-1, -1);
                -webkit-transform: scale(-1, -1);
                transform: scale(-1, -1);
            }
        }
    }
    > ul{
        position: relative;
        white-space: nowrap;
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        flex-wrap: nowrap;
        flex-flow: row;
        > li{
            white-space: normal;
            flex-shrink: 0;
            padding-left: 15px;
            padding-right: 15px;
            width: 33.33%;
            @media only screen and (max-width: 1500px) {
                width: 50%;
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
}
